@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.webhook-create {
  &__header {
    padding: 10px;
    min-height: auto !important;
    width: 100%;
    &-container {
      display: flex;
      align-items: center;
      gap: 20px;

      & > span {
        font-size: 16px;

        &:before {
          content: "";
          height: 16px;
          width: 1px;
          background: rgba(0, 0, 0, 0.3);
          // position: absolute;
          display: inline-block;
          transform: translate(-13px, 3px);
        }
      }
    }
  }

  &__container {
    padding-top: 50px;
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  &__heading {
    font-size: 28px;
    font-weight: 700;
    width: 400px;
    padding: 0;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 16px;
    color: rgb(65, 69, 82);
    margin-bottom: 20px;
    width: 400px;
  }

  &__form {
    width: 400px;
    &-control {
      label {
        display: block;
        font-size: 16px;
        margin-bottom: 10px;
      }

      input {
        width: 100%;
        padding: 4px 8px;
        border-radius: 5px;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease-in-out;

        &:focus {
          border: 1px solid #2075f5;
        }
      }
    }

    &-select-event {
      margin-top: 20px;
      & > span {
        display: block;
        font-size: 16px;
        margin-bottom: 10px;
      }

      & > button {
        border: none;
        border-radius: 16px;
        padding: 8px;
        color: #ffffff;
        background: rgb(242, 235, 255);
        color: #2075f5;
        font-size: 14px;
        display: flex;
        align-items: center;

        &.webhook-create__button-edit {
          background: transparent;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        &:hover {
          color: #000000;

          svg {
            width: 14px;
            height: 14px;
            filter: invert(0%) sepia(95%) saturate(0%) hue-rotate(121deg)
              brightness(100%) contrast(106%);
          }
        }

        svg {
          width: 14px;
          height: 14px;
          filter: invert(31%) sepia(95%) saturate(1958%) hue-rotate(206deg)
            brightness(99%) contrast(94%);
        }
      }
    }

    &-button-group {
      margin-top: 20px;
      display: flex;
      gap: 10px;

      & > button:first-of-type {
        border: none;
        padding: 8px;
        border-radius: 8px;
        background: #2075f5;
        color: #ffffff;
        outline: none;
        display: flex;
        align-items: center;
        gap: 5px;

        & > svg {
          width: 12px;
          height: 12px;
          animation: spin 2s linear infinite;
        }
      }

      & > button:last-of-type {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.3);
        outline: none;
        border-radius: 8px;
        color: #000000;
      }
    }
  }

  &__event-button {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      width: 8px !important;
      height: 8px !important;
    }
  }
}
