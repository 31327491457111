.card-order {
  padding-top: 24px;
  //border-radius: 0;
  .order--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef1;
    flex: none;
    order: 0;
    flex-grow: 0;
    .card-details-header-content-id {
      a {
        color: #2075f5;
      }
    }
    &-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      margin-right: 24px;
      gap: 8px;
      // width: 320px;
      height: 28px;
      // border-top: 1px solid #EBEEF1;
      flex: none;
      order: 1;
      flex-grow: 0;
      position: relative;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 22px;
        margin: 0;
        gap: 8px;
        color: #3c4257;
        height: 28px;
        background: #ffffff;
        border: 1px solid #e0e6eb;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
          0px 2px 5px rgba(60, 66, 87, 0.08);
        border-radius: 4px;
        a {
          color: #3c4257;
        }
      }
    }
  }
  .card-details-header-content {
    margin-left: 24px;
  }
  .orders__view_table {
    margin-top: 24px;
    tr {
      &:nth-child(2n) {
        background: #f3f6f9;
      }
      td {
        padding: 14px;
        &.text-gray-800 {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #8c979f;
        }
        &.text-grey-600 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
        }
        .order-status-td {
          padding: 0;

          &-success {
            color: #01a51c;
          }
          &-failed {
            color: #e75f5f;
          }
          &-panding {
            color: #ffbf0a;
          }
        }
      }
    }
  }
  .card-details-header-content-body {
    &-name {
      // line-height: 19px;
      padding-top: 3px;
    }
  }
  .card-details-header-content-id {
    margin-top: 4px;
    a {
      margin-left: 0;
    }
  }
}

.table-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  // height: 760px;
  padding: 24px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  .main-table {
    width: 100%;
    .order-details-tr {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px 0px 4px;
      gap: 8px;
      margin-top: 8px;
      width: 100%;
      height: 24px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      .td-definition {
        width: 180px;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #8c979f;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      .td-answer {
        // width: 700px;
        height: 20px;
        font-weight: 600;
        a {
          color: inherit;
          text-decoration: none;
        }
        font-size: 14px;
        line-height: 20px;
        color: #30313d;
        flex: none;
        order: 1;
        flex-grow: 0;
        img {
          margin-right: 4px;
        }
        .order-status-td {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border-radius: 4px;
          padding: 1px 6px;
          gap: 4px;
          width: fit-content;
          height: 18px;

          &-success {
            background: #d7f7c2;
            color: #05690d;
          }
          &-failed {
            background: #ffe7f2;
            color: #b3063d;
          }
          &-panding {
            background: #fcedb9;
            color: #a82c00;
          }
        }
        .payment-method {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px;
          // width: 56px;
          height: 20px;
        }
      }
      .with-color {
        color: #2075f5;
      }
    }
  }
}
.user-status-td {
  width: fit-content;
  height: 18px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 4px;
  padding: 1px 6px;
  gap: 4px;
  &.user-status-success {
    background: #d7f7c2;
    color: #05690d;
  }
  &.user-status-pending {
    width: fit-content;
    height: 18px;
    background: #fcedb9;
    color: #a82c00;
  }
  &.user-status-sending {
    background: #ffe7f2;
    color: #b3063d;
  }
  &.user-status-not-send {
    background: #cff5f6;
    color: #0055bc;
  }
}
.order-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row !important;
}
.comments-section {
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  color: rgb(26, 31, 54);

  .textarea-section {
    textarea {
      width: 100%;
      resize: none;
      color: rgb(26, 31, 54);
      border-color: rgb(109, 162, 241);
      border-radius: 5px;
      background-color: #3c425708;
      padding: 5px;
    }
  }
}
.payment-method-provider {
  display: flex;
  column-gap: 0.5rem;
}
