.confirm-status-modal{
  .react-confirm-pro__body{
    min-width: 200px !important;
    background: #fff;
    max-width: 300px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .status-modal-title{
      font-style: normal;
      font-weight: bold;
      text-align: center;
      color: #193040;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
    .status-modal-content{
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      .status-modal-description{
        font-style: normal;
        font-weight: bold;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #6A7383;
        p{
          margin-bottom: 0;
        }
      }
      .status-modal-textarea{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0;
        textarea{
          max-width: 312px;
          width: 100%;
          height: 64px;
          background: #F3F6F9;
          border-radius: 4px;
          padding: 7px 12px 7px 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 13px;
          align-items: center;
          color: #8C979F;
          border: none;

          &:active,
          &:focus{
            outline: 0;
            border: 0;
            box-shadow: 0;
          }
        }
      }
    }
    
    .react-confirm-pro__btn{
      &-close{
        display: none;
      }
    }
    .react-confirm-pro__actions{
      display: flex;
      justify-content: center;
      margin-top: 16px !important;
      .react-confirm-pro__btn{
        padding: 4px 8px;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        box-shadow: none;
  
        &-confirm{
          background: #ffffff;
          color: #3C4257;
          border: 1px solid #E0E6EB;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(60, 66, 87, 0.08);
          border-radius: 4px;
        }
      }
    }
  }
  
  &.confirm-status-block-modal{
    .react-confirm-pro__actions{
      .react-confirm-pro__btn{
        &-alert{
          background: #2075F5;
          color: #ffffff;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);
          border: 1px solid #635BFF;
        }
      }
    }
  }
  &.confirm-status-unblock-modal{
    .react-confirm-pro__actions{
      .react-confirm-pro__btn{
        &-alert{
          background:#2075F5;
          color: #ffffff;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);
          border: 1px solid #635BFF;
        }
      }
    }
  }
  
}
.confirm-status-score-modal{
  .react-confirm-pro__actions{
    .react-confirm-pro__btn{
      &-alert{
        background: #2075F5;
        color: #fff;
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);
      }
    }
  }
}


.custom-buttons {
    text-align: center;
    button {
      padding: 4px 8px;
      outline: none;
      border-radius: 4px;
      border: none;
      margin-right: 8px;
      &:hover {
        box-shadow: 0px 0px 0px #bdbdbd, 0px 0px 0px #ffffff, inset 4px 4px 8px #bdbdbd, inset -4px -4px 8px #ffffff;
      }
    }
  }