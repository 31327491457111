.aside-secondary-enabled.aside-fixed {
  .wrapper {
    // padding-left: 60px!important;
    padding-left: 0px !important;
  }
}
@media (max-width:991.98px){
  .aside-secondary-enabled.aside-fixed {
    .wrapper {
      padding-left: 0!important;
    }
  }
}
