.promotion-wrapper {
  width: 100%;
  table {
    width: 100%;
    max-width: 50rem;
    font-size: 2rem;
    font-weight: 400;
    thead {
      tr {
        border-bottom: 1px solid #ebeef1;
        th {
          padding: 2rem 0;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            color: gray;
          }
          &:nth-child(2) {
            font-weight: 600;
          }
          padding: 0.8rem 0;
          font-size: 1.2rem;
          text-align: start;
          color: #30313d;
        }
      }
    }
  }
}
