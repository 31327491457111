@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700"');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

@font-face {
    font-family: "SF-Pro";
    src: url("../../fonts/SF-Pro.otf") format("opentype");
}

@font-face {
    font-family: "SF-Mono";
    src: url("../../fonts/SFMonoRegular.woff") format("woff");
    font-weight: normal;
}

@font-face {
    font-family: "SF-Mono";
    src: url("../../fonts/SFMonoMedium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "SF-Mono";
    src: url("../../fonts/SFMonoSemibold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "SF-Mono";
    src: url("../../fonts/SFMonoBold.woff") format("woff");
    font-weight: 900;
}