.donut-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  border: 0.5px solid #ebeef1;
  padding: 2rem;
  .donut-chart-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    .donut-chart-descripton {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .donut-chart-descripton-title {
        font-weight: 500;
        font-size: 14px;
        color: #6a7383;
      }
      .donut-chart-descripton-progress {
        width: fit-content;
        padding: 2px 6px;
        border-radius: 6px;

        font-size: 12px;
        font-weight: 500;
      }
      .green {
        color: #05690d;
        background-color: #d7f7c2;
      }
      .red {
        color: #b3063d;
        background-color: #ffe7f2;
      }
    }
    .donut-chart-count {
      font-weight: 400;
      font-size: 20px;
    }
  }
}
