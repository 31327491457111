.card.setting-card {
  // padding: 24px 24px 0px;
  .card-details-tabs {
    margin: 0px;

    .tabs-button {
      padding: 0 0 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6a7383;
      border: none;
      background: none;
      margin-right: 24px;

      &-active {
        color: #2075f5;
        border-bottom: 2px solid #2075f5;
      }
    }
  }
}
.setting-content {
  margin-top: 29px;

  .card {
    // padding: 24px;
    height: 80vh;

    &-list-content-row {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 24px;
      margin-top: 0;

      &-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        color: #3c4257;
        margin-right: 15px;
      }
      &-value {
        display: flex;
        align-items: center;
      }
    }
    .card-list-setting {
      display: flex;
      flex-direction: row;
      align-items: center;

      .card-list-content-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 421px;
        width: 100%;
        &.ml-24 {
          margin-left: 24px;
        }
        &-title {
          font-weight: bold;
        }
        &-value {
          width: 100%;
          margin-top: 11px;

          .global-select-container {
            width: 100%;

            .global-select__control {
              height: 43px;
              background: #f3f6f9;
              border-radius: 8px;
              border: 0 !important;
              box-shadow: none !important;
              &:active,
              &:focus {
                outline: 0 !important;
                border: 0 !important;
                box-shadow: none !important;
              }
              &--menu-is-open {
                outline: 0 !important;
                border: 0 !important;
                box-shadow: none !important;
              }
            }
            .global-select__menu {
              padding: 8px;
              background: #f3f6f9;
              box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
              border-radius: 8px;
              .global-select__option {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 13px;
                color: #526470;
                margin-top: 8px;
                border-radius: 8px;
                padding: 8px;
                cursor: pointer;
                transition: all 0.25s ease-in;
                background: none;
                text-transform: uppercase;

                &--is-focused {
                  background: none;
                }
                &--is-selected {
                  background: rgba(32, 175, 245, 0.1);
                  color: #2075f5;
                }
                &:first-child {
                  margin-top: 0;
                }
                &:hover {
                  background: rgba(32, 175, 245, 0.1);
                  color: #2075f5;
                }
              }
            }
            .global-select__single-value {
              color: #526470;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
            }
            .global-select__indicator-separator {
              background: none;
            }
          }
        }
      }
    }
  }
}

.card {
  &.partner-setting {
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    .setting-header {
      padding: 0px 0px 16px;
      gap: 4px;
      height: 56px;
      border-bottom: 1px solid #ebeef1;
      font-family: "SF Pro Display";
      margin-bottom: 10px;
      p {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        align-items: center;
        letter-spacing: 0.340402px;
        color: #30313d;
      }
    }
    .setting-text {
      margin-top: 24px;
      margin-bottom: 0;
      color: #8c979f;
      width: fit-content;
      height: 20px;
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .btn-copy {
      width: fit-content;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #3c4257;
      padding: 4px 8px;
      gap: 10px;
      background: #ffffff;
      border: 1px solid #e0e6eb;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
        0px 2px 5px rgba(60, 66, 87, 0.08);
      &.copied {
        border: 1px solid #2075f5;
      }
      // svg{
      //   margin-right: 8px;
      // }
    }
    .clip-card {
      margin: 16px 0;
      .iframe_sw {
        align-items: flex-start;
        padding: 16px;
        width: fit-content;
        background: #f6f8fa;
        border-radius: 4px;
        flex: none;
        order: 1;
        flex-grow: 0;
        box-sizing: border-box;
      }
      .iframe_sw p,
      .iframe_sw span {
        font-family: "Courier Prime", monospace;
      }
      .iframe_sw p {
        font-family: "SF Pro Text";
        font-weight: 400;
        flex: none;
        order: 0;
        flex-grow: 0;

        word-wrap: break-word;
        margin: 0;
        color: #526470;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
      .iframe_sw .p_b {
        color: #2075f5;
      }
      .iframe_sw .p_s {
        color: #8fb699;
      }
      .iframe_sw .p_ch {
        color: #4fa8ab;
      }
      .iframe_sw p.p_atribute {
        padding-left: 80px;
      }
      .iframe_sw .p_k {
        color: #ef5880;
      }
      .iframe_sw .iframe_title {
        color: #2075f5;
      }
      .iframe_sw .p_bl {
        color: #51a5d1;
      }
      .iframe_sw .p_g {
        color: #c87e70;
      }
      .integrate .btn_dark {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 1200px) {
        .integrate.section_sw .description {
          margin-right: 20px;
        }
      }
      @media screen and (max-width: 768px) {
        .integrate.section_sw .description {
          order: 1;
          margin-right: 0;
        }
        .integrate.section_sw .banner_sw {
          order: 2;
        }
      }
    }
  }
}
