.section-summary {
  .rows {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    @media screen and (max-width: 1500px) {
      flex-direction: column;
      gap: 30px;
    }
    @media screen and (max-width: 1400px) {
      flex-direction: column;
      gap: 50px;
    }
    &.spaceY-3 {
      margin: 30px 0 0 0;
      @media screen and (max-width: 1400px) {
        margin: 50px 0 0 0;
      }
    }
    .card {
      padding: 15px;
      flex-basis: 50%;
      .box-header {
        margin-bottom: 24px;
        @media screen and (max-width: 576px) {
          margin-bottom: 20px;
        }

        &-summary {
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 47px;
          color: #193040;
          @media screen and (max-width: 576px) {
            font-size: 30px;
            line-height: 37px;
          }
        }
        &-name {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #c5cbcf;
          @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        &-table-title {
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 22px;
          color: #181c32;
          text-transform: capitalize;
          //  @media screen and (max-width:576px) {
          //   font-size: 15px;
          // }
        }
      }
      .box-content {
        display: flex;
        flex-direction: column;
        .table-scrolling {
          min-height: 20vh;
        }
        .rows {
          &.rows-total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            @media screen and (max-width: 1400px) {
              flex-direction: row;
              // gap: 50px;
            }
            &.spaceY-1 {
              margin: 10px 0;
              padding: 10px 0;
              border-top: 1px solid rgba(0, 0, 0, 0.15);
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }
            .cols {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }
            &.success {
              .cols {
                color: #01a51c;
              }
            }
            &.blocked {
              .cols {
                color: #e75f5f;
              }
            }
          }
          &.rows-country {
            margin-bottom: 10px;
            padding: 10px 0;
            border-bottom: 1px solid #e8eaec;
            .cols {
              font-style: normal;
              font-size: 14px;
              line-height: 16px;

              /* Main/Black 50 */

              &.text-grey {
                color: #8c979f;
                font-weight: 500;
              }
              &.text-black {
                color: #193040;
                font-weight: bold;
              }
            }
          }
        }
        .table-small {
          flex: 1 1 auto;
          thead {
            tr {
              border: 0;
              th {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: #8c979f;
                vertical-align: middle;
                padding-bottom: 16px;
                padding-top: 0;
                position: relative;
                box-sizing: border-box;
                @media screen and (max-width: 576px) {
                  font-size: 15px;
                  padding-top: 0 !important;
                }

                &:first-child {
                  text-align: left;
                  padding-right: 5px !important;
                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 98%;
                    height: 1px;
                    background: #e8eaec;
                  }
                }
                &:last-child {
                  text-align: right;
                  padding-left: 5px !important;
                  padding-right: 0px !important;
                  .table-title-border {
                    justify-content: flex-end;
                  }
                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 98%;
                    height: 1px;
                    background: #e8eaec;
                  }
                }
                label {
                  margin-left: 5px;
                  display: inline-flex;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }
          }
          tbody {
            tr {
              &.card-tr {
                border-bottom: 1px solid #e8eaec;
                td {
                  font-style: normal;
                  font-size: 14px;
                  line-height: 16px;
                  padding: 10px 0;
                  &:first-child {
                    text-align: left;
                    font-weight: 500;
                    color: #8c979f;
                  }
                  &:last-child {
                    text-align: right;
                    font-weight: bold;
                    color: #526470;
                  }
                }
              }
            }
          }
        }
        .pagination {
          padding: 0;
          margin-top: 20px;
          li {
            a {
              width: 30px;
              height: 30px;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: #526470 !important;
              padding: 8px 16px;
              background: #f3f6f9;
              border-radius: 8px;
            }
            &.active {
              a {
                color: #fff !important;
              }
            }
            &:hover {
              a {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
.payment-component {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.charts-dashboard-container {
  .title {
    font-size: 24px;
    font-weight: 700;
    margin: 1.5rem 0;
  }
  .tabs-padding {
    margin: 1rem 0;
  }
}
