.pagination {
  user-select: none;
  li {
    padding: 0 4px;
    a {
      display: inline-flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      background-color: #f5f8fa;
      color: #7e8299!important;
      border-radius: 4px;
      font-weight: bold;
      &:hover {
        background-color: #04c8c8;
        color: #ffffff!important;
      }
    }
    &.active {
      a {
        background-color: #04c8c8;
        color: #ffffff!important;
      }
    }
  }
}

@media (max-width: 650px) {
  .pagination li a {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background-color: #f5f8fa;
    color: #7e8299 !important;
    border-radius: 4px;
    font-weight: bold;
    margin-top: 20px;
  }
}
