.webhooks-list {
  &__container {
    width: 100%;
    padding-top: 20px;
  }

  &__top-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);

    & > button {
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      padding: 8px;
    }
  }

  &__header {
    font-size: 20px;
    margin: 0;
  }

  &__table {
    width: 100%;

    thead {
      th {
        padding-bottom: 10px;
        &:last-of-type {
          text-align: end;
        }
      }
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    }

    tbody {
      td {
        padding: 10px 0;
        font-size: 16px;
        &:first-of-type {
          font-weight: 700;
        }
        &:last-of-type {
          text-align: end;

          & > div {
            margin-left: auto;
          }
        }

        .menu-item {
          padding: 0;
        }

        button {
          margin-left: auto;
        }

        & > span.webhooks-list__status {
          background: green;
        }
      }
    }
  }
}
