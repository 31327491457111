.revenue-charts-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .revenue-charts-border {
    border: 0.5px solid #ebeef1;
  }
  .charts-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 0.5px solid #ebeef1;
  }
  .revenue-charts-big-chart {
    display: flex;
  }
}
.twice-width {
  grid-column: span 2;
}

.top-filter-revenue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
