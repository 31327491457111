.revenue-charts-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .revenue-charts-border {
    border: 0.5px solid #ebeef1;
  }
  .charts-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 0.5px solid #ebeef1;
  }
  .revenue-charts-big-chart {
    display: flex;
  }
}
.top-filter-financial {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 15px;
}
.charts-big-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  border: 0.5px solid #ebeef1;
}
