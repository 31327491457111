.financial-reports {
  width: 1200px;
  overflow: hidden;
  overflow-x: scroll;

  table {
    .no-wrap {
      white-space: nowrap;
    }
    th {
      text-transform: uppercase;
      text-wrap: nowrap;
      padding: 1rem;
      color: #30313d;
      font-weight: 500;
    }
    td {
      padding: 1rem;
      color: #6a7383;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .reports-overrall {
    td {
      font-weight: 700;
      font-size: 14px;
      background-color: #f6f8fa;
    }
  }
}

tr {
  border-bottom: 1px solid #ebeef1;
}
