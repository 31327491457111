.line-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.7rem;
  border: 0.5px solid #3c42571f;
  canvas {
    width: 100%;
  }
  .example-chart {
    width: 100%;
    min-height: 150px;
  }
  &.empty {
    width: 100%;
    height: 100%;
    min-height: 195px;
    .not-found-section {
      height: 150% !important;
    }
  }
  .line-chart-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    .line-chart-descripton {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .line-chart-descripton-title {
        font-weight: 500;
        font-size: 14px;
        color: #6a7383;
      }
      .line-chart-descripton-progress {
        width: fit-content;
        padding: 2px 6px;
        border-radius: 4px;

        font-size: 12px;
        font-weight: 500;
      }
      .green {
        color: #05690d;
        background-color: #d7f7c2;
      }
      .red {
        color: #b3063d;
        background-color: #ffe7f2;
      }
    }
    .line-chart-count {
      font-weight: 400;
      font-size: 20px;
    }
    .line-chart-sub-count {
      font-weight: 400;
      font-size: 12px;
      color: #6a7383;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
