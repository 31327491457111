.select-event {
  &__container {
    padding-top: 50px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  &__back-button {
    padding: 0;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
    filter: invert(39%) sepia(74%) saturate(4567%) hue-rotate(207deg)
      brightness(100%) contrast(92%);
    font-size: 14px;

    &:hover {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(45deg)
        brightness(91%) contrast(100%);
      color: #000000;
    }
  }

  &__header {
    font-size: 28px;
    color: rgb(65, 69, 82);
    width: 400px;
    margin-bottom: 10px;
  }

  &__search-container {
    width: 400px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
    }

    input {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
      padding-left: 30px;
      transition: all 0.3s ease-in-out;

      &:focus {
        border: 1px solid #2075f5;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__select-all {
    margin-top: 10px;
    margin-bottom: 10px;

    input {
      margin: unset !important;
    }

    button {
      width: fit-content;
      padding: 5px;

      &.button.clipboard-button {
        & > svg {
          stroke: none;
        }
      }
    }

    .tooltip__inner-container {
      position: absolute;
      top: -260% !important;
      left: -520%;
      background: #fff;
      padding: 6px;
      background: #ffffff;
      border: 1px solid #e3e8ee;
      border-radius: 4px;

      svg {
        width: 20px;
        position: absolute;
        bottom: -12%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
    }

    .tooltip__content {
      width: 300px !important;
      font-size: 14px;
      line-height: 1.5;
    }

    svg {
      width: 12px;
      height: 12px;
    }

    width: 400px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__accordion-container {
    width: 400px;
  }

  &__accordion-container {
    margin-top: 10px;
  }

  &__accordion-header {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    font-size: 14px;
    padding-right: 30px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    position: relative;
    transition: all 0.2s ease-in-out;

    & > span:first-of-type {
      padding-bottom: 5px;
    }

    svg {
      position: absolute;
      right: 0;
    }

    &.active {
      svg {
        transform: rotate(-180deg);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__accordion-badge {
    margin: 0 auto 0 10px;
  }

  &__event-list {
    max-height: calc(100vh - 300px);
    overflow: scroll;
    padding-right: 10px;
    padding-left: 10px;
  }

  &__accordion-body {
    display: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;

    &.active {
      display: block;
      max-height: unset;
      transition: max-height 0.2s ease-out;
    }

    & > ul {
      margin-top: 10px;
      & > li {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        input {
          margin: unset !important;
        }

        label {
          display: block;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        span {
          font-size: 14px;
          color: rgb(104, 115, 133);
        }
        margin-bottom: 10px;
      }
    }
  }

  &__button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
    width: 410px;

    & > button:first-of-type {
      border: none;
      outline: none;
      background: #2075f5;
      color: #ffffff;
      padding: 8px;
      border-radius: 4px;
    }

    & > button:last-of-type {
      background: transparent;
      border: none;
      border-radius: 4px;
      outline: none;
      color: #000000;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}
