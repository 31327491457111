.card.setting-card {
  // padding: 24px 24px 0px;
  .card-details-tabs {
    margin: 0px;

    .tabs-button {
      padding: 0 0 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6A7383;
      border: none;
      background: none;
      margin-right: 24px;

      &-active {
        color: #2075F5;
        border-bottom: 2px solid #2075F5;
      }
    }
  }
}
.setting-content {
  margin-top: 29px;

  .card {
    // padding: 24px;
    height: 80vh;
    .bitcoin-core--tabs {
      display: flex;
      gap: 100px;
      .card-list-content-row {
        margin-top: 12px;
        max-width: 253px;
        width: 100%;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child {
          margin-top: 0;
        }
        &-header {
          font-weight: 400;
          color: #8C979F;
          font-size: 14px !important;
          line-height: 20px;
        }
        &-body {
          color: #526470;
          font-weight: 400;
          font-size: 14px !important;
          line-height: 20px;
          font-weight: bold;
          color: #30313D;
          .card-fee-rate {
            // width: 66px;
            height: 29px;
            display: flex;
            align-items: center;
            // padding: 5px;
            gap: 5px;
            &.disabled {
              background: #f3f6f9;
              border-radius: 5px;
              padding-left: 5px;
              padding-right: 5px;
            }
            input {
              border: 0;
              outline: 0;
              background: transparent;
              width: 34px;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #30313D;
            }
            button {
              border: 0;
              outline: 0;
              background: transparent;
              width: 12px;
              height: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: unset;
            }

            svg {
              width: 12px;
              height: 12px;
            }
          }
          .react-switch.false {
            .react-switch-bg {
              background: #c5cbcf !important;
            }
          }
        }
      }
    }

    &-list-content-row {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 24px;
      margin-top: 0;

      &-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        color: #3C4257;
        margin-right: 15px;
      }
      &-value {
        display: flex;
        align-items: center;
      }
    }
    .card-list-setting {
      display: flex;
      flex-direction: row;
      align-items: center;

      .card-list-content-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 421px;
        width: 100%;
        &.ml-24 {
          margin-left: 24px;
        }
        &-title {
          font-weight: bold;
        }
        &-value {
          width: 100%;
          margin-top: 11px;

          .global-select-container {
            width: 100%;

            .global-select__control {
              height: 43px;
              background: #f3f6f9;
              border-radius: 8px;
              border: 0 !important;
              box-shadow: none !important;
              &:active,
              &:focus {
                outline: 0 !important;
                border: 0 !important;
                box-shadow: none !important;
              }
              &--menu-is-open {
                outline: 0 !important;
                border: 0 !important;
                box-shadow: none !important;
              }
            }
            .global-select__menu {
              padding: 8px;
              background: #f3f6f9;
              box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
              border-radius: 8px;
              .global-select__option {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 13px;
                color: #526470;
                margin-top: 8px;
                border-radius: 8px;
                padding: 8px;
                cursor: pointer;
                transition: all 0.25s ease-in;
                background: none;
                text-transform: uppercase;

                &--is-focused {
                  background: none;
                }
                &--is-selected {
                  background: rgba(32, 175, 245, 0.1);
                  color: #2075F5;
                }
                &:first-child {
                  margin-top: 0;
                }
                &:hover {
                  background: rgba(32, 175, 245, 0.1);
                  color: #2075F5;
                }
              }
            }
            .global-select__single-value {
              color: #526470;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
            }
            .global-select__indicator-separator {
              background: none;
            }
          }
        }
      }
    }
  }
}
