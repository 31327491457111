.event-details {
  &__container {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  &__banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & > button {
      background: transparent;
      border: none;
      outline: none;
      color: #2075f5;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__header {
    font-size: 28px;
    margin: 0;
    margin-left: 5px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  }

  &__additional-info {
    margin-top: 10px;
    padding-left: 5px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;

      & > span:last-of-type {
        text-decoration: underline;
      }
    }
  }

  &__badge {
    padding: 4px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(175deg)
        brightness(101%) contrast(101%);
    }

    &.failed {
      background: rgba(0, 0, 0, 0.09);
    }

    &.success {
      background: #d7f7c2;
      color: #05690d;
    }
  }

  &__code-block {
    padding-left: 5px;
    margin-top: 20px;

    & > h2 {
      font-size: 20px;
    }

    &--inner {
      max-height: 220px;
      overflow: hidden;
      position: relative;

      &.active {
        max-height: unset;
      }
    }
  }

  &__code-wrapper {
    & > button {
      margin-top: 10px;
      margin-left: 100px;
      border: 0.5px solid rgba(0, 0, 0, 0.3);
      padding: 6px 8px;
      border-radius: 4px;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active {
        margin-top: 0;
        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        transform: scale(1.01);
      }
    }
  }

  &__code-blur {
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(#8a636300, #fff);
  }

  &__webhook-attempts {
    margin-top: 50px;
    padding-left: 5px;
  }

  &__attempts-banner {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 10px;

    & > div {
      & > span:first-of-type {
        display: block;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      & > span:last-of-type {
        font-size: 14px;
      }
    }
  }

  &__table {
    width: 100%;

    thead {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
      th {
        padding: 10px;
        text-transform: uppercase;
      }
    }

    tbody {
      tr {
        transition: all 0.3s ease-in-out;
        &:hover:not(.event-details__request-tr) {
          background: rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }
      }

      td {
        padding: 10px;
        font-size: 14px;

        .event-details__status-wrapper {
          display: flex;
          gap: 20px;
          align-items: center;

          & > svg {
            width: 12px;
            height: 12px;
            transition: all 0.3s ease-in-out;
          }

          &.active {
            & > svg {
              transform: rotate(90deg);
            }
          }
        }

        & > button {
          background: #ffffff;
          border-radius: 4px;
          border: 0.5px solid rgba(0, 0, 0, 0.3);
          padding: 4px 8px;
          display: flex;
          gap: 5px;
          align-items: center;
          margin-left: auto;

          svg {
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(175deg)
              brightness(101%) contrast(101%);
          }
        }
      }
      td:last-of-type {
        text-align: end;
      }
    }
  }

  &__request-container {
    max-width: 1200px;
  }

  &__status-info {
    text-align: start;
    margin-bottom: 10px;
    margin-top: 10px;
    & > span:first-of-type {
      display: inline-block;
      text-align: start;
      min-width: 180px;
      max-width: 240px;
    }
  }

  &__request-info {
    text-align: start;
    display: flex;

    & > span:first-of-type {
      display: inline-block;
      text-align: start;
      min-width: 180px;
      max-width: 240px;
      margin-top: 5px;
    }

    & > div {
      border: 0.5px solid rgba(0, 0, 0, 0.3);
      padding-bottom: 10px;

      pre {
        margin: 0 !important;
      }
    }
  }

  &__code-snippet {
    max-width: 995px;
    width: 100%;
  }

  &__response-info {
    margin-top: 10px;
    text-align: left;
    & > span:first-of-type {
      display: inline-block;
      text-align: start;
      min-width: 180px;
      max-width: 240px;
      margin-top: 5px;
    }

    & > div {
      display: inline-block;
    }
  }
}
