@import "./variables";

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.user-status-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  &.user-status-success {
    background: rgba(1, 165, 28, 0.15);
    color: rgba(1, 165, 28, 1);
  }
  &.user-status-pending {
    background: rgba(255, 191, 10, 0.25);
    color: rgba(190, 150, 35, 1);
  }
  &.user-status-sending {
    background: rgba(32, 175, 245, 0.25);
    color: rgba(32, 175, 245, 1);
  }
  &.user-status-not-send {
    background: rgba(231, 95, 95, 0.2);
    color: rgba(231, 95, 95, 1);
  }
}

iframe {
  // z-index: 0 !important;
  // position: static !important;
  display: none !important;
}
.react-datepicker-wrapper {
  width: auto !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
  max-width: max-content !important;
}
.select-label-row {
  display: flex;
  align-items: center;
  img {
    width: 17px;
    height: 17px;
  }
  span {
    display: inline-block;
    margin-left: 6px;
    line-height: 16px;
  }
}

.table-scrolling table tbody > tr:nth-of-type(2) {
  td {
    &:last-child {
      .menu.menu-sub.menu-sub-dropdown.change-status--menu.show:not(.last) {
        bottom: unset;
      }
    }
  }
}
tbody > tr:nth-last-of-type(-n + 2):not(.last) {
  td {
    &:last-child {
      .menu.menu-sub.menu-sub-dropdown.change-status--menu.show:not(.last) {
        // top: -95px !important;
        // top: 0px;
        top: unset !important;
        bottom: 100%;
      }
    }
  }
}
tbody > tr:nth-last-of-type(-n + 2) {
  td {
    &:last-child {
      .menu.menu-sub.menu-sub-dropdown.change-status--menu.show:not(.last) {
        // top: -95px !important;
        // top: 0px;
        top: unset !important;
        bottom: 100%;
      }
    }
  }
}
.partner--table {
  tbody > tr:nth-last-of-type(-n + 2) {
    td {
      &:last-child {
        .menu.menu-sub.menu-sub-dropdown.change-status--menu.show:not(.last) {
          top: -130px !important;
          top: 0px;
        }
      }
    }
  }
}
.clients--tabs,
.logs--tabs,
.order__table {
  tbody > tr:nth-last-of-type(-n + 2) {
    td {
      &:last-child {
        .menu.menu-sub.menu-sub-dropdown.change-status--menu.show:not(.last) {
          // top: -55px !important;
          top: 0px;
        }
      }
    }
  }
}
.text--end .menu.menu-sub.menu-sub-dropdown.change-status--menu.show {
  z-index: 105;
  position: absolute;
  right: 0;
  // top: 50px;
  margin: 0px;
  padding: 8px;
  width: 201px;
  background: #f3f6f9;
  box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
  border-radius: 8px;
  .menu-item {
    background: none;
    margin-top: 8px;
    &.mt-0 {
      margin-top: 0;
    }
    &:hover {
      background: rgba($primary-color, 0.1);
      border-radius: 8px;
      padding: 0;
    }
    .menu-link {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      color: #193040;
      padding: 8px;
      background: none;

      &:hover {
        color: $primary-color;
      }
    }
    .confirm-change-status-btn.menu-link {
      border: 0;
      width: 100%;
    }
  }
}

// User list style start
.user-table {
  .card > .card-body {
    padding: 0;
  }
}
.card {
  box-shadow: none;
  .card-details-header-content-id {
    a {
      color: #8c979f;
    }
  }

  .row-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 24px;
    &:first-child {
      justify-content: space-between;
    }
    &.row-list-two {
      margin-top: 35px;
      .col-list {
        flex-direction: column;
        flex-basis: 401px !important;
      }
    }
    .row-list-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin: 0;
      // flex: 1 1 332px;
      &.w-0 {
        min-width: 332px;
      }
    }
    .row-list-right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin: 0;
      width: 100%;
    }
    .col-list {
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      min-width: 250px;
      max-width: 300px;

      // flex-basis: 401px;
      &.col-list-right {
        justify-content: flex-start;
      }
      &.col-list-center {
        justify-content: center;
      }
      &.col-list-left {
        justify-content: flex-start;
      }
      .col-list-title {
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 13px;
        color: #193040;
        padding-left: 2px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .error--messege {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #8e6900;
          display: inline-block;
          padding: 4px 8px;
          background: #ffefc4;
          border-radius: 4px;
          margin-left: 10px;
        }
      }
      .col-reset {
        .btn-reset {
          background: none;
          border: 1px solid #8c979f !important;
          box-sizing: border-box;
          border-radius: 8px;
          color: #526470;
          padding: 10px 24px !important;
        }
      }

      &.col-custom-select {
        max-width: 421px;
        width: 100%;
        // margin-left: 24px;
        .custom_select {
          width: 100%;
        }
        .custom_select_style__control {
          height: 43px;
          background: #f3f6f9;
          border-radius: 8px;
          border: 0 !important;
          box-shadow: none !important;
          &:active,
          &:focus {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          &.custom_select_style__control--menu-is-open {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          .custom_select_style__indicator-separator {
            background: none;
          }
        }
        .custom_select_style__menu {
          padding: 8px;
          background: #f3f6f9;
          box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
          border-radius: 8px;
          .custom_select_style__menu-list {
            .custom_select_style__option {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 13px;
              color: #8c979f;
              margin-top: 8px;
              border-radius: 8px;
              padding: 8px;
              cursor: pointer;
              transition: all 0.25s ease-in;
              background: none;
              text-transform: uppercase;

              &--is-focused {
                background-color: rgba(32, 175, 245, 0.1);
                color: $primary-color;
              }

              &:first-child {
                margin-top: 0;
              }
              &:hover {
                background: rgba(32, 175, 245, 0.1);
                color: $primary-color;
              }
            }
          }
        }
        &.col-list-status {
          max-width: 160px;
        }
      }
      .box {
        display: flex;
        align-items: center;
        height: 43px;
        &.calendar--box {
          width: 100%;
          .calendar--range-container {
            width: 100%;
            .calendar--range-input {
              width: 100%;
            }
          }
        }
        &.m_16 {
          margin: 0 16px;
        }
        .data-picker-col {
          width: 206.5px;
          &.ml_8 {
            margin-left: 8px;
          }
          .react-datepicker-wrapper {
            width: 100%;
          }
        }
        .data_picker {
          width: 100%;
          height: 43px;
          background-color: #f3f6f9;
          background-image: url("../img/calendar-icon.svg");
          background-repeat: no-repeat;
          background-size: 16px 16px;
          background-position: right 10px center;
          border-radius: 8px;
          border: 0;
          padding: 0 10px;
          &.react-datepicker-ignore-onclickoutside:active,
          &.react-datepicker-ignore-onclickoutside:focus {
            outline: none !important;
            border: none !important;
            box-shadow: 0 !important;
            outline-width: 0;
          }
        }
      }
      .mr_16 {
        margin-right: 16px;
        display: flex;
        align-items: flex-start;
        align-self: center;
        align-content: center;
        gap: 5px;
        font-weight: normal;
        font-size: 14px;
        color: #193040;
        height: 16px;
      }
      &.ip-row {
        margin-left: 24px;
        .ip-col {
          max-width: 155px;
          width: 100%;
          .ip-input {
            border: 0;
            width: 100%;
            padding: 10px;
            background: #f3f6f9;
            border-radius: 8px;
            height: 43px;
            color: #8c979f;
            &.ip-to {
              margin-left: 8px;
            }
            &:active,
            &:focus {
              outline: 0;
              border: 0;
              box-shadow: 0;
            }
          }
        }
      }
    }
    .col-list-kyc,
    .col-list-log-type,
    .col-list-country {
      display: flex;
      // flex-basis: 401px;
      // margin: 0 24px;
      .react-select {
        // width: 100%;
        .css-6j8wv5-Input {
          margin: 0;
          width: 0;
        }
        .select-multi__control {
          height: 29px;
          background: transparent;
          border-radius: 8px;
          border: 0 !important;
          box-shadow: none !important;
          &:active,
          &:focus {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          .select-multi__value-container {
            padding: 0;
            .select-multi__placeholder {
              display: none !important;
            }
          }
          .select-multi__input {
            pointer-events: none !important;
            opacity: 0 !important;
            width: 0 !important;
            margin: 0;
          }
          .select-multi--menu-is-open {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          .select-multi__indicator-separator {
            background: none;
          }
          .select-multi__clear-indicator {
            display: none;
          }
          .select-multi__multi-value {
            background: #f3f6f9;
            border-radius: 5px;
            padding: 5px 4px;
            span {
              font-size: 16px;
            }
          }
          .select-multi__multi-value__label {
            background: none;
            padding: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #193040;
          }
          .select-multi__multi-value__remove {
            background: none;
            padding: 0;
            color: unset !important;
            svg {
              padding: 0;
            }
          }
          .select-multi__indicators {
            align-items: flex-start;
            padding-top: 5px;
          }
          .select-multi__dropdown-indicator {
            padding: 3px 12px;
            border: 1px solid #8c979f;
            box-sizing: border-box;
            border-radius: 100px;
            color: #8c979f;
            cursor: pointer;
          }
        }
      }
      .select-multi__menu {
        min-width: 170px !important;
        padding: 8px;
        background: #f3f6f9;
        box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
        border-radius: 8px;
        .select-multi__menu-list {
          .select-multi__option {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 13px;
            color: #8c979f;
            margin-top: 8px;
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            transition: all 0.25s ease-in;
            text-transform: uppercase;

            &:first-child {
              margin-top: 0;
            }
            &:hover {
              background: rgba(32, 175, 245, 0.1);
              color: $primary-color;
            }
            &--is-focused {
              background: none;
            }
          }
          .select-multi__option:checked {
            background-image: url("../../components/base/Icon/icons/selected-icon.png");
            background-size: 14px 13px;
          }
        }
      }
    }
    .col-list-kyc {
      .select-multi__multi-value__label::first-letter {
        font-size: 13px;
      }
    }

    .col-list-status {
      // max-width: 360px;
      .react-select-status {
        // width: 100%;
        .react-select-status-styles__placeholder {
          display: none !important;
        }
        .react-select-status-styles__input-container {
          margin: 0;
          width: 0;
        }
        .react-select-status-styles__input {
          pointer-events: none !important;
          opacity: 0 !important;
          width: 0 !important;
        }
        .react-select-status-styles__control {
          height: 29px;
          background: transparent;
          border-radius: 8px;
          border: 0 !important;
          box-shadow: none !important;
          &:active,
          &:focus {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          .react-select-status-styles__placeholder {
            font-size: 14px;
          }
          .react-select-status-styles__value-container {
            padding: 0;
          }
          .react-select-status-styles--menu-is-open {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
          .react-select-status-styles__indicator-separator {
            background: none;
          }
          .react-select-status-styles__clear-indicator {
            display: none;
          }
          .react-select-status-styles__multi-value {
            border-radius: 5px;
            padding: 5px 7px;
          }
          .react-select-status-styles__multi-value__label {
            padding: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
          }
          .react-select-status-styles__multi-value__remove {
            background: none;
            padding-right: 0;
            svg {
              padding: 0;
            }
          }
          .react-select-status-styles__indicators {
            align-items: flex-start;
            padding-top: 5px;
          }
          .react-select-status-styles__dropdown-indicator {
            padding: 3px 12px;
            border: 1px solid #8c979f;
            box-sizing: border-box;
            border-radius: 100px;
            color: #8c979f;
            cursor: pointer;
          }
        }
      }
      .react-select-status-styles__menu {
        padding: 8px;
        background: #f3f6f9;
        box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
        border-radius: 8px;
        min-width: 150px;
        .react-select-status-styles__menu-list {
          .react-select-status-styles__option {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 13px;
            color: #8c979f;
            margin-top: 8px;
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            background: none;
            text-transform: uppercase;
            &:first-child {
              margin-top: 0;
            }
            &--is-focused {
              background-color: none;
              background-position: right 8px center;
              background-repeat: no-repeat;
              background-size: 14px 13px;
              transition: none;
            }
            &:hover {
              background: rgba(32, 175, 245, 0.1);
              color: $primary-color;
              background-image: none;
            }
          }
        }
      }
    }
    .col-list-country {
      display: flex;
      // flex-basis: 401px;
      .new-select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        background: #f3f6f9;
        border-radius: 8px;
        padding: 7px 12px 7px 8px;

        &-input {
          background: transparent;
          border: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #526470;
          max-width: 220px;
          width: 100%;
          &:active,
          &:focus {
            outline: 0 !important;
            border: 0 !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
}
.table--pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &-select {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);

    label {
      margin-right: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #526470;
    }
    .custom_select_style__control {
      padding: 8px 16px;
      background: #f3f6f9;
      border-radius: 8px;
      border: 0;

      &--menu-is-open,
      &--is-focused {
        border: 0;
        box-shadow: none;
      }
    }
    .custom_select_style__value-container {
      padding: 0 8px 0 0;
    }
    .custom_select_style__input-container {
      padding-top: 0;
      padding-bottom: 0;
    }
    .custom_select_style__indicator {
      padding: 0 0 0 8px !important;
      &-separator {
        display: none;
      }
    }
  }

  .pagination--buttons-wrapper {
    & > button:first-of-type {
      margin-right: 8px;
    }
  }

  .table--result {
    font-size: 14px;
    line-height: 20px;
    color: #6a7383;
  }
}
.form-check-input.user-list-checkbox {
  margin-right: 10px;
  margin-left: 5px;
  background-size: 12px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: 1px solid #c0c8d2;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.12);
  display: block;
  &.checked,
  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
  }
}
.user-list-sort {
  display: flex;
  margin-left: 5px;
  padding: 0;
  cursor: pointer;
  &.ml-0 {
    margin-left: 0;
    margin-right: 5px;
  }
}
.user-list-header {
  padding-bottom: 24px;
  margin-bottom: 8px;
  // overflow-x: auto;
  // overflow-y: hidden;
  // padding-top: 15px;
}

.user-list-header-tr {
  border-bottom: 1px solid #ebeef1 !important;
  th {
    vertical-align: middle;
    flex-direction: row;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    color: #1a1f36;
    position: relative;
    padding: 16px 50px 8px 0 !important;

    &.partner-email {
      padding-right: 0 !important;
    }
    &.min-w-125px {
      min-width: 125px;
    }
    &.min-w-175px {
      min-width: 192px !important;
    }
    &.min-w-50px {
      padding-right: 0 !important;
      .table-title-border {
        justify-content: flex-end;
      }
    }
    &.min-w-50px {
      .table-title-border {
        &::after {
          width: 0;
          display: none;
        }
      }
    }
    &.light {
      .table-title-border {
        color: $primary-color;
      }
    }
    .table-title-border {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      white-space: nowrap;
    }

    &.th-email,
    &.th-transaction-id {
      padding-right: 0 !important;
      width: fit-content;
    }

    &.th-tansaction-id,
    &.th-customer-id,
    &.th-partner-id,
    &.th-tier,
    &.th-limit {
      padding-right: 0 !important;
    }

    &.suspend-status {
      padding-right: 0 !important;
    }
  }
}

.reload-btn {
  border: none;
  background: #f4f5f5;
  border-radius: 8px;
  padding: 10px;
}

.user-list-body {
  .user-list-tr {
    border-bottom: 1px solid #ebeef1;
    height: 56px;

    &.align-top {
      & > td {
        vertical-align: top;

        &.td-edit-status {
          padding-top: 0 !important;
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid #ebeef1 !important;
    }
    .log-action-btn {
      padding: 4px 0 !important;
    }
    td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6a7383;
      padding: 8px 50px 8px 0 !important;
      vertical-align: middle;
      border: none;
      white-space: nowrap;
      height: 56px;
      &:nth-of-type(2):not(.table-id-link) &:nth-of-type(2):not(.log-table-id),
      &.partner-email,
      &:last-of-type,
      &.td-tier,
      &.td-limit {
        padding-right: 0 !important;
      }
      &.user-list-td-id {
        color: #2595df;
      }
      &.user-list-td-log {
        width: 300px;
        span {
          color: #8c979f;
          border-bottom: 1px dashed #8c979f;
          cursor: pointer;
        }
      }
      &.user-list-td-grey {
        white-space: nowrap;
        color: #526470;
        a {
          color: #193040;
        }

        div {
          white-space: nowrap;
        }
      }
      &.user-list-td-black {
        a {
          color: #526470;
          text-decoration: underline;
        }
      }
      td {
        padding: 0 !important;
        border: 0;
      }
      .btn--action.btn {
        padding: 8.5px 10.04px !important;
        // background: #e8eaec;
        border-radius: 5px;
      }
      .risk--score-num {
        display: flex;
        align-items: center;
        img {
          margin-left: 8px;
        }
      }
      .kyc--status {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
      .verification-col {
        display: inline-flex;
        align-items: center;
        a {
          margin-left: 8px;
        }
        span {
          display: inline-block;
          margin-left: 8px;
        }
      }

      // &.user-email {
      //   .clipboard-button > svg {
      //     stroke: #526470;
      //   }
      // }

      .user-status-td {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 0 6px;
        border-radius: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        & svg {
          margin-left: 4px;
          width: 12px;
          height: 12px;
        }
        &.transaction-status-td {
          width: fit-content;
        }
        &.user-status-active {
          background: #d7f7c2;
          color: #05690d;
          padding: 0 6px;
        }
        &.user-status-process {
          background: rgba(32, 175, 245, 0.15);
          color: rgb(32, 175, 245);

          & > svg {
            fill: rgb(32, 175, 245);
          }
        }
        &.user-status-info {
          background: #cff5f6;
          color: #0055bc;

          & > svg {
            fill: #0055bc;
          }
        }
        &.user-status-blocked {
          background: #ffe7f2;
          color: #b3063d;
        }
        &.user-status-pending {
          background: rgba(255, 191, 10, 0.25);
          color: #be9623;
        }
        &.user-status-panding {
          background: rgba(255, 191, 10, 0.25);
          color: #be9623;

          & > svg {
            fill: #be9623;
          }
        }
      }

      div {
        white-space: nowrap;
      }

      &.order-email {
        .order-email__inner-container {
          display: flex;
          align-items: center;
          width: fit-content;
          margin: 0;
        }
      }

      &.td-email,
      &.order-email,
      &.td-transaction-status,
      &.balance-status,
      &.balance-description {
        padding-right: 0 !important;
      }

      &.td-transaction-status {
        .button {
          padding: 0 !important;
        }
      }

      &.order-id {
        // padding-top: 17px !important;
        padding-right: 0 !important;
      }

      &.table-id-link {
        padding-right: 0 !important;
        width: fit-content;
        div {
          display: flex;
          align-items: center;
          width: fit-content;
        }
        a {
          color: $primary-color;
        }
      }

      &.td-edit-status {
        svg {
          width: 12px;
          height: 12px;
          fill: $dark-grey;
        }
        & > button {
          padding-bottom: 10px;
          padding-left: 16px;
        }
      }

      &.edit-tier {
        text-align: center;
      }

      &.suspend-status {
        padding-right: 0 !important;
      }

      &.description {
        display: flex;
        align-items: center;
        gap: 10px;

        & > p {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all 0.43s ease-in-out;
        }

        & > button {
          background: transparent;
          border: none;
          outline: none;
          color: $dark-grey;
          font-size: 14px;
          line-height: 1.2;
          font-weight: 400;
          padding: 0;
          border-bottom: 1px solid $dark-grey;
          box-shadow: none;
          border-radius: unset;
          min-width: 32px;
        }

        &.show-more {
          height: auto;
          align-items: flex-start;
          & > p {
            width: 300px;
            overflow: visible;
            word-break: break-all;
            white-space: break-spaces;
            transition: all 0.43s ease-in-out;
          }
        }
      }

      &.blockchain-link {
        & > a {
          text-decoration: none;
          color: $primary-color;
        }
      }
    }

    .menu-item {
      padding: 0;
      box-shadow: unset;

      &:hover {
        padding: 0;
      }
    }

    .button-tr-status {
      padding: 0;
      border: none;
      outline: none;
      background: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .table.table-row-dashed tr {
    border-bottom-width: 0;
    border-bottom-style: none;
    border-bottom-color: none;
  }

  button {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.table-scrolling {
  overflow-x: auto;
  min-height: 605px;
}
.table--row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  .user-list-body {
    width: 100%;
  }
  .table--setting {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    padding: 16px 0;
  }
}

.log-list-row {
  .user-list-body,
  .user-list-header {
    .user-list-tr {
      border-bottom: none;
      .text-grey {
        color: #526470;
      }
    }
  }
}
.log-description {
  max-width: 300px;
  width: 100%;
  display: block;
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #8c979f;
}
.risk-score-card {
  .user-list-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .user-list-tr {
    border: 0 !important;
  }
}
.point-change {
  color: #8c979f;
  display: flex;
  align-items: center;
  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    &.plus {
      color: #01a51c;
    }
    &.minus {
      color: #e75f5f;
    }
  }
  small {
    margin: 0 20px;
    display: inline-block;
  }
}
.points-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .points-col {
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 13px;
      letter-spacing: -0.02em;
      color: #171d32;
    }
    .points-btn {
      padding: 5px 7px;
      background: rgba(255, 191, 10, 0.25);
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #be9623;
    }
    .confirm-change-status-btn.menu-link.btn-score {
      padding: 15px 24px;
      background: $primary-color;
      border-radius: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      text-decoration: none;
      border: 0;
    }
  }
}
.risk_score-modal-input {
  margin: 24px 0;
  input {
    padding: 10px 24px;
    background: #f3f6f9;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #c5cbcf;
    max-width: 138px;
    width: 100%;
    border: 0;
    &:active,
    &:focus {
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }
}
.card-order {
  // padding: 24px;
  box-shadow: none;
  .order--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-button {
      button {
        background: rgba(32, 175, 245, 0.1);
        border-radius: 8px;
        padding: 15px 24px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        text-align: center;
        color: $primary-color;
        border: 0;
      }
    }
  }
  .card-details-header-content {
    margin-left: 24px;
  }
  .orders__view_table {
    margin-top: 24px;
    tr {
      &:nth-child(2n) {
        background: #f3f6f9;
      }
      td {
        padding: 14px;
        &.text-gray-800 {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #8c979f;
        }
        &.text-grey-600 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
        }
        .order-status-td {
          padding: 0;

          &-success {
            color: #01a51c;
          }
          &-failed {
            color: #e75f5f;
          }
          &-panding {
            color: #ffbf0a;
          }
        }
      }
    }
  }
  .card-details-header-content-body {
    &-name {
      // line-height: 19px;
      padding-top: 3px;
    }
  }
  .card-details-header-content-id {
    margin-top: 4px;
    a {
      margin-left: 0;
    }
  }
}
.order-table {
  .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    // fill: #1c1c1f;
  }
  td.td-color-gray {
    color: #526470;
    a {
      color: #526470;
      text-decoration: underline;
    }
  }
  td.td-color-black {
    color: #193040;
    a {
      color: #193040;
      text-decoration: underline;
    }
  }
  td {
    &:nth-of-type(11):not(.partner-status):not(.td-clipboard) {
      padding-right: 0 !important;
    }
    .integrated {
      background: rgba(1, 165, 28, 0.15);
      border-radius: 5px;
      padding: 5px 7px;
      color: #01a51c;
      width: 117px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .not_integrated {
      background: rgba(231, 95, 95, 0.2);
      border-radius: 5px;
      padding: 5px 7px;
      color: #e75f5f;
      width: 117px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .row-list.row-list-header {
    justify-content: flex-start;
    margin-top: 24px;
    gap: 0px;
    .col-list {
      margin-right: 24px;
    }
    &.flex--wrap {
      flex-wrap: wrap;

      &.rows-for-admin {
        // gap: 12px;
        .col-list {
          &:nth-child(1) {
            order: 1;
          }
          &:nth-child(2) {
            order: 2;
          }
          &:nth-child(3) {
            order: 3;
          }
          &:nth-child(4) {
            order: 4;
            // flex-basis: 100% !important;
            // height: 24px;
            margin: 0 !important;
            padding: 0 !important;
          }
          &:nth-child(5) {
            order: 5;
          }
          &:nth-child(6) {
            order: 6;
          }
          &:nth-child(7) {
            order: 7;
          }
        }
      }

      &.rows-for-partner {
        .col-list {
          &:nth-child(1) {
            order: 1;
          }
          &:nth-child(2) {
            order: 4;
            flex: 1 1 100% !important;
            margin-top: 24px;
          }
          &:nth-child(3) {
            order: 2;
          }
          &:nth-child(4) {
            order: 3;
          }
        }
      }
    }

    .col-list {
      display: flex;
      flex-direction: column;
      flex-basis: 401px !important;
      @media screen and (max-width: 1483px) {
        flex-basis: 350px !important;
      }
      @media screen and (max-width: 1350px) {
        flex-basis: auto !important;
      }
      &.flex-auto {
        flex-basis: auto !important;
      }
      .col-input-rang {
        display: flex;
        flex-direction: row;
        width: 100%;
        .input-range {
          background: #f3f6f9;
          border-radius: 8px;
          border: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 13px;
          color: #8c979f;
          height: 43px;
          padding: 0 0 0 10px;
          box-sizing: border-box;
          max-width: 217px;
          width: 100%;
          &:focus,
          &:active {
            border: 0;
            outline: 0;
          }
          &::placeholder {
            font-style: 14px;
          }

          &.ml-8 {
            margin-left: 8px;
          }
        }
      }
      .calendar--range-input {
        max-width: 217px;
        width: 100%;
        padding: 0 0 0 10px;
        box-sizing: border-box;
      }
      .react-select-status-styles__multi-value {
        padding: 5px !important;

        &__label {
          line-height: 18px !important;
        }
      }
    }
  }
}
//  Order list style end
// partner list style start
.partner--row {
  .row-list.row-list-one {
    margin-top: 24px;
    justify-content: space-between;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #193040;
  }
}
.partner--card {
  .card-details-header-content-id {
    a {
      margin-left: 0;
    }
  }
  .black-text {
    a {
      color: #193040;
    }
  }
  .card-list-content-iframe {
    max-width: 513px;
    color: #8c979f;
    cursor: pointer;

    & > a {
      color: #30313d !important;

      &:hover {
        color: darken($color: #30313d, $amount: 10) !important;
      }
    }
  }
  .card-list-header-row.mt_8 {
    margin-top: 8px;
  }
  .card-list-header-row-col {
    padding: 8px 0px 8px 8px;
    background: #f3f6f9;
    border: 1px dashed #e4e6ee;
    box-sizing: border-box;
    border-radius: 8px;
    width: 148px;
    height: 81px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #8c979f;
      letter-spacing: 0.02px;
    }
    b {
      margin-top: 8px;
    }
  }
}
.partner--row {
  td.td-color-black {
    color: #193040;
    a {
      color: #193040;
      text-decoration: underline;
    }
  }
  td {
    .integrated {
      display: inline-block;
      background: rgba(1, 165, 28, 0.15);
      border-radius: 5px;
      padding: 5px 7px;
      color: #01a51c;
      width: 117px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .not_integrated {
      display: inline-block;
      background: rgba(231, 95, 95, 0.2);
      border-radius: 5px;
      padding: 5px 7px;
      color: #e75f5f;
      width: 117px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.partner_details-filter {
  .btn.btn-light-primary.btn--lightblue {
    border: 1px solid #8c979f !important;
    color: #526470 !important;
    background: #fff !important ;
    .svg-icon-filter {
      svg {
        rect {
          fill: #526470 !important;
        }
      }
    }
    &:hover {
      background: #fff !important ;
      color: #526470 !important;
    }
  }
  .btn.btn-light-primary.btn--lightblue:hover {
    background: #fff !important;
    transition: background-color 0.5s !important;
  }
}
// partner list style end
.restricted--countries {
  .row-list .col-list.ip-row .ip-col {
    width: 221px;
    max-width: 221px;
  }
  .table-scrolling {
    min-height: 70vh;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .row-list-left,
  .row-list-right {
    width: none !important;
  }
  .row-list .col-list-country {
    display: flex;
    // flex-basis: 401px;
    margin: 0 0 0 24px;
  }
}
.btn.btn-green.btn-add-partner {
  background: #01a51c;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  padding: 15px 25.75px !important;
}

.user-status-td {
  vertical-align: middle !important;
}
.card {
  &-toolbar {
    .react-datepicker-wrapper.order-date {
      width: auto !important;
    }
  }
}
.partner-modal-switch {
  .modal-content-col {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .form-switch.form-check-solid .form-check-input {
    width: 32px !important;
    height: 18px !important;
    border-radius: 12px !important;
  }
}

.react-switch.active {
  .react-switch-handle {
    height: 18px !important;
    width: 18px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(5%, -50%) !important;
    transition: background-color 0s ease, transform 0s ease, box-shadow 0s ease !important;
    border: 1px solid #3d4eac !important;
    box-shadow: 0px 2px 5px rgba(42, 47, 69, 0.1),
      0px 1px 1.5px rgba(0, 0, 0, 0.07) !important;
  }
  .react-switch-bg {
    width: 32px !important;
    height: 18px !important;
  }
}

.react-switch.false {
  .react-switch-handle {
    height: 18px !important;
    width: 18px !important;
    left: 60% !important;
    top: 50% !important;
    transform: translate(-100%, -50%) !important;
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s,
      box-shadow 0.15s ease 0s !important;
    border: 1px solid #3d4eac !important;
    box-shadow: 0px 2px 5px rgba(42, 47, 69, 0.1),
      0px 1px 1.5px rgba(0, 0, 0, 0.07) !important;
  }
  .react-switch-bg {
    width: 32px !important;
    height: 18px !important;
  }
}
@media (min-width: 992px) {
  .d-flex.d-lg-none.align-items-center.ms-n2.me-2 {
    display: none !important;
  }
}

.svg-icon.svg-icon-1 {
  svg {
    width: 16px !important;
    height: 16px !important;
    padding-right: 0 !important;
  }
}
.aside-secondary-enabled .aside {
  width: 100px !important ;
}
.aside-secondary-disabled .aside {
  width: 100px;
}
.aside-secondary-disabled .aside .aside-primary {
  border-right: 0;
}
[data-kt-aside-minimize="on"] .aside {
  width: 425px !important;
  transition: width 0.3s ease;
}

// .color:hover {
//   color: #00afaf !important;
//   cursor: pointer;
// }

.wrapper .header_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  background: $light-grey;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .input__wrapper {
    position: relative;
  }

  .logo__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .logo__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $header-black;
  }
}

.wrapper {
  min-width: 1100px;
  overflow-x: scroll;
  &.dashboard__content {
    min-height: 100vh;
  }

  & > div {
    min-height: 100vh;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // justify-content: space-between;
  }
}

.header {
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  height: auto;
}

@media (min-width: 992px) {
  .aside-secondary-enabled.aside-fixed .wrapper {
    padding-left: 100px !important;
  }
}
.aside-secondary-enabled.aside-fixed[data-kt-aside-minimize="on"] .wrapper {
  padding-left: 425px !important;
}
.rotate-180 {
  transform: rotateZ(-180deg) !important;
}
.active > .rotate-180 {
  transform: rotateZ(0deg) !important;
}

.aside__nav_link_item svg {
  fill: #a1a5b7 !important;
}
.aside__nav_link_item:hover > svg {
  fill: #04c8c8 !important;
}
.aside__nav_link_item:hover > svg circle {
  fill: #21aff5 !important;
}
.aside__nav_link_item.active > svg circle {
  fill: #21aff5 !important;
}
.aside__nav_link_item.active > svg {
  fill: #04c8c8 !important;
}

.content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 1200px;
  padding: 0 !important;
  // padding-right: 40px;
  // padding-left: 40px;
  // margin-right: auto;
  // margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
  // .dashboard__content .container,
  // .dashboard__content .container-sm,
  // .dashboard__content .container-md {
  //   max-width: 850px;
  // }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
  // .dashboard__content .container,
  // .dashboard__content .container-sm,
  // .dashboard__content .container-md,
  // .dashboard__content .container-lg {
  //   max-width: 960px;
  // }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
  // .dashboard__content .container,
  // .dashboard__content .container-sm,
  // .dashboard__content .container-md,
  // .dashboard__content .container-lg,
  // .dashboard__content .container-xl {
  //   max-width: 1140px;
  // }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
  .dashboard__content .container,
  .dashboard__content .container-sm,
  .dashboard__content .container-md,
  .dashboard__content .container-lg,
  .dashboard__content .container-xl,
  .dashboard__content .container-xxl {
    max-width: 100%;
  }
}

.table__orders .fs-7 {
  font-size: 0.9rem !important;
}
.table__orders .list__orders td {
  font-size: 0.9rem;
}

.aside-fixed.aside-secondary-enabled[data-kt-sticky-header="on"] .header {
  left: 100px;
  transition: left 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  .modal-header-title-m {
    margin-bottom: 0 !important;
  }
}

.btn.btn-icon.btn-sm.btn-active-icon-primary {
  position: absolute;
  left: 550px;
}

@media (max-width: 995px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 65% !important;
  }
}

@media (max-width: 780px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 70% !important;
  }
}

@media (max-width: 640px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 70% !important;
  }
}

@media (max-width: 590px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 75% !important;
  }
}

@media (max-width: 550px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 80% !important;
  }
}

@media (max-width: 515px) {
  .w-lg-500px.bg-white.rounded.shadow-sm.p-10.p-lg-15.mx-auto {
    width: 100% !important;
  }
}

@media (max-width: 321px) {
  .text-center {
    margin-right: 50px !important;
  }
}

@media (max-width: 315px) {
  .text-dark.mb-3 {
    padding-left: 52px;
  }
}

@media (max-width: 322px) {
  .text-dark.mb-3 {
    padding-left: 58px;
  }
}

@media (max-width: 272px) {
  .text-dark.mb-3 {
    padding-left: 47px;
  }
}

@media (max-width: 1203px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    z-index: 999;
  }
}

@media (max-width: 992px) {
  .aside-fixed.aside-secondary-enabled[data-kt-sticky-header="on"] .header {
    left: 0px;
  }

  .modal-content-container.modal-header {
    position: relative;
  }

  .fv-row.mb-7 {
    display: flex;
    justify-content: space-between;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    width: 100% !important;
  }

  .aside {
    display: block;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 999;
    .aside-primary {
      height: 100vh;
    }
    .modal-content-container {
      width: 520px !important;
    }
  }
  .page.d-flex.flex-row.flex-column-fluid {
    display: none !important;
  }

  .aside.aside-extended.bg-white {
    width: 70px !important;
  }
  .svg-icon.svg-icon-2x {
    color: #5b5c60 !important;
  }
  .row.g-0.mb-7 {
    display: flex;
  }
  .card-px {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .fs-6.text-gray-400 {
    width: 110px !important;
  }
  .fs-2 {
    font-size: calc(1.1rem + 0.3vw) !important;
  }
  .fs-6 {
    font-size: 1.1rem !important;
  }
  .less {
    display: block !important;
  }
}
.orders__view_table .flex-equal {
  margin-right: 30px;
  padding-right: 10px !important;
}
.orders__view_table .flex-equal tr td {
  padding: 10px 0 !important;
}

@media (max-width: 321px) {
  .fs-6.text-gray-400 {
    width: 75px !important;
  }
  .fs-6 {
    font-size: 15px !important;
  }
  .fs-2 {
    font-size: calc(1rem + 0.3vw) !important;
  }

  .w-250px {
    width: 195px !important;
  }

  .d-flex.justify-content-end {
    text-align: center;
  }
}

.d-flex {
  flex-wrap: inherit !important;
  display: flex !important;
}

.menu-item.px-3:hover {
  color: white !important;
  background-color: #21aff5 !important;
}
.sign-out-modal.react-confirm-pro {
  .react-confirm-pro__body {
    box-sizing: border-box;
    max-width: 300px;
    min-width: 300px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 15px 35px rgba(60, 66, 87, 0.12),
      0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .react-confirm-pro__btn {
      &.react-confirm-pro__btn-close {
        display: none;
      }
    }
    .sign-out-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #193040;
    }
    .sign-out-description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #8c979f;
      margin: 8px auto 16px auto;
      max-width: 130px;
    }
    .react-confirm-pro__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .react-confirm-pro__btn {
        padding: 15px 24px;
        border-radius: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        text-align: center;
        border: 0;
        box-shadow: none;

        &-confirm {
          background: #ffffff;
          color: #3c4257;
          padding: 4px 8px;
          border: 1px solid #e0e6eb;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
            0px 2px 5px rgba(60, 66, 87, 0.08);
          border-radius: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }
        &-alert {
          padding: 4px 8px;
          color: #ffffff;
          background: $primary-color;
          border: 1px solid $primary-color;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
            0px 1px 1px rgba(0, 0, 0, 0.08),
            inset 0px -1px 1px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.menu-item {
  padding: 0;
  &:first-of-type {
    box-shadow: inset 0 -1px rgb(235, 238, 241);
    padding: 10px;
  }
  .menu-content {
    padding: 0;
    .mr-16 {
      margin-right: 16px;
    }
    .admin-name {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 13px;
      letter-spacing: -0.02em;
      color: #171d32;
      padding-top: 5px;
    }
    .admin-email {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #8c979f;
      margin-top: 8px;
    }
  }
  // .menu-link{
  //   padding: 0;
  // }
  // &.mt-24 {
  //   margin-top: 24px;
  // }
  // &.mb-24 {
  //   margin-bottom: 24px;
  // }
  a.menu-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #526470;
    padding: 0;
    &.sign-out-link {
      color: #e75f5f;
    }
  }
}
.menu-item__partner-name {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1b25;
}
.menu-item__role {
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: $text-grey;
}
.menu-item__partner-email {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #8c979f;

  &:hover {
    color: $primary-color;
  }
}
// }
@media (max-width: 516px) {
  .d-flex.justify-content-end {
    justify-content: flex-start !important;
  }
}

@media (max-width: 573px) {
  .menu-sub-dropdown.menu.show {
    left: 0 !important;
  }
}

@media (max-width: 406px) {
  .btn.btn-light-primary.me-3 {
    margin-top: 10px !important;
  }
}

@media (max-width: 515px) {
  .btn3 {
    margin-top: 5px !important;
  }
}

@media (max-width: 700px) {
  .modal-content-container {
    width: 550px !important;
  }
}

@media (max-width: 570px) {
  .modal-content-container {
    width: 490px !important;
  }
}

@media (max-width: 505px) {
  .modal-content-container {
    width: 380px !important;
  }
}

@media (max-width: 400px) {
  .modal-content-container {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .fv-row.mb-7 {
    justify-content: center;
    display: block;
    align-items: center;
  }
  .ReactModalPortal {
    z-index: 999 !important;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ps-10 {
    padding-left: 0.9rem !important;
  }

  .orders__view_table.flex-equal {
    padding-right: 10px !important;
  }
}

@media (max-width: 1225px) {
  .header.dashboard__content {
    z-index: 1 !important;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    z-index: 999 !important;
  }
}

@media only screen and (max-width: 625px) {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 320px;
  }
}

.ReactModalPortal {
  z-index: 8;
}

@media (max-width: 308px) {
  .my-1 {
    width: 120px;
  }
  .form-control.form-control-solid.w-250px.ps-14 {
    padding-left: 28px !important;
  }
  .ms-6 {
    margin-left: 0.5rem !important;
  }
}

@media (max-width: 280px) {
  .my-1 {
    width: 50px;
  }
}

@media (max-width: 281px) {
  .form-control.form-control-solid.w-250px.ps-14 {
    padding-left: 28px !important;
  }

  .ms-6 {
    margin-left: 0 !important;
  }
}

.card.pt-4.mb-6.mb-xl-9 {
  overflow: auto;
}

.bg-primary {
  background-color: #21aff5 !important;
}

.aside__nav_link_item.active > svg {
  fill: #21aff5 !important;
}

a {
  color: $primary-color;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #21aff5 !important;
  color: #ffffff !important;
}
.pagination li.active2 a {
  background-color: #21aff5 !important;
  color: #ffffff !important;
}

.btn-check:active + .btn.btn-light-primary,
.btn-check:checked + .btn.btn-light-primary,
.btn.btn-light-primary.active,
.btn.btn-light-primary.show,
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary:focus:not(.btn-active),
.btn.btn-light-primary:hover:not(.btn-active),
.show > .btn.btn-light-primary {
  background-color: #21aff5 !important;
}
.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled),
.nav-line-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #21aff5;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.text-active-primary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #21aff5 !important;
}

.pagination li a:hover {
  background-color: #21aff5 !important;
  color: #ffffff !important;
}

.button {
  padding: 9px;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &-secondary {
    background: #ffffff;
    color: #6a7383;
    border: 1px solid #e0e6eb;

    &:disabled {
      pointer-events: none;
      background: darken($color: #ffffff, $amount: 8);
    }
    &:hover {
      background: darken($color: #ffffff, $amount: 4);
    }
    &:active {
      background: darken($color: #ffffff, $amount: 6);
    }
  }

  &--transparent {
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
  }

  &.filter__button {
    border: 1px dashed rgba(4, 36, 68, 0.25);
    border-radius: 100px;
    background: #ffffff;
    outline: none;
    font-size: 12px;
    color: #6a7383;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    box-shadow: none;

    &.active {
      border-style: solid;
    }

    svg {
      fill: #6a7383;
    }
  }

  &-add-partner {
    padding: 4px 8px;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
      0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &-add,
  &-cancel {
    padding: 4px 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #3c4257;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
      0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12) !important;
    margin: unset;
  }
}

.button-primary {
  background: $primary-color;
  color: #ffffff;
  border: 1px solid $primary-color;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn.btn-primary {
  color: #fff;
  border-color: $primary-color;
  background-color: $primary-color;
  &:disabled {
    color: #8c979f;
    border-color: #e4e6ee;
    background-color: #e4e6ee;
  }
}
.btn.btn-gray {
  color: #8c979f;
  border-color: #e4e6ee;
  background-color: #e4e6ee;
}

.btn-check:active + .btn.btn-primary,
.btn-check:checked + .btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.show > .btn.btn-primary {
  color: #fff;
  background-color: rgba($primary-color, 0.8);
}

.btn-check:active + .btn.btn-active-light-primary,
.btn-check:checked + .btn.btn-active-light-primary,
.btn.btn-active-light-primary.active,
.btn.btn-active-light-primary.show,
.btn.btn-active-light-primary:active:not(.btn-active),
.btn.btn-active-light-primary:focus:not(.btn-active),
.btn.btn-active-light-primary:hover:not(.btn-active),
.show > .btn.btn-active-light-primary {
  color: #fff;
  border-color: #dcfdfd;
  background-color: #21aff5;
  .svg-icon.svg-icon-5 {
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
}

.btn.btn-light-primary.me-3.btn2 {
  color: #fff !important;
  border-color: #dcfdfd;
  background-color: #73bee7 !important;
}

.btn.btn-light-primary.me-3.btn2:hover {
  background-color: #21aff5 !important;
  transition: background-color 0.5s !important;
}

.btn.btn-light-primary.me-3 {
  background-color: #21aff5 !important;
  color: #ffffff !important;
}

.btn.btn-light-primary.btn--lightblue {
  color: #fff;
  background: $primary-color;
  border-radius: 8px;
}
.btn.btn-light-primary.btn--lightblue:hover {
  background-color: $primary-color;
  transition: background-color 0.5s !important;
}

.btn.btn--lightgrey {
  background: #e4e6ee;
  border-radius: 8px;
  color: #fff;
}

.menu-link.px-3:hover {
  background-color: #0d8ddc;
  transition: background-color 5s !important;
}

.menu-link.px-3:hover {
  color: white !important;
}
.svg-icon-filter {
  margin-left: 10px;
}
.svg-icon.svg-icon-2 {
  svg {
    path {
      fill: #ffffff !important;
    }
  }
}

.menu-link.px-5:hover {
  color: #21aff5 !important;
}

.fw-bold.text-muted.text-hover-primary.fs-7:hover {
  color: #21aff5 !important;
}

.nav-link.btn.btn-icon.btn-active-color-primary.btn-color-gray-400.btn-active-light.aside__nav_link_item:hover {
  svg {
    path {
      color: #21aff5 !important;
    }
  }
}

.aside__nav_link_item:hover svg {
  fill: #21aff5 !important;
}
.aside__nav_link_item:hover:hover > svg {
  fill: #21aff5 !important;
}
.aside__nav_link_item.active:hover > svg {
  fill: #21aff5 !important;
}

@media (max-width: 550px) {
  .card-rounded.bg-white.mt-n10.position-relative.card-px.py-15 {
    display: flex;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 15px !important;
  }

  .col {
    flex: auto !important;
    margin-top: 15px !important;
  }

  .fs-6.text-gray-400 {
    width: auto !important;
  }

  .fs-6 {
    font-size: 22px !important;
    margin-bottom: 5px !important;
  }
  .fs-2 {
    font-size: 20px !important;
  }
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #0570de !important;
}

.w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
  padding: 42px;
}
.new_password_title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #193040;
  margin-bottom: 10px;
}
.ask_about_have_password {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8c979f;
}
.add_phone {
  .ask_about_have_password {
    padding: 0 20px;
    max-width: 388px;
    width: 100%;
  }
}
.ask_about_have_password a {
  color: $primary-color;
  margin-left: 5px;
  font-size: 16px;
  line-height: 19px;
}
.mb-34 {
  margin-bottom: 34px;
}
.mb-40 {
  margin-bottom: 40px;
}
.text_dark {
  color: #193040;
}
.input_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.p-15-25.form-control-lg {
  padding: 15px 25px;
}
.form-control.form-control-styles {
  background: #f3f6f9;
  border: 1px solid #f3f6f9;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #8c979f;
}
.input_view {
  position: relative;
}
.lines_validity.h-3px {
  height: 3px;
}
.line_input.m__10 {
  margin: 10px 0;
}
.input_validity_info {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #c5cbcf;
}
.input__wrapper {
  height: 24px;

  .input {
    border: 1px solid #e0e6eb;
    border-radius: 12px;
    background: #ffffff;
    color: #6a7383;
    font-size: 12px;
    line-height: 16px;
    height: 100%;
    outline: none;
    transition: all 0.3s ease-out;

    &:focus {
      border-color: $primary-color;
    }

    &:focus + svg {
      fill: $primary-color;
    }

    &__search {
      padding-left: 28px;
      min-width: 420px;
    }
  }

  svg {
    position: absolute;
    left: 8px;
    top: 6px;
    fill: #6a7383;
  }
}

.fv-row.mb-40 {
  margin-bottom: 40px;
}

.form-agree_label {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 16px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #8c979f;
    display: inline-block;
    a {
      color: $primary-color;
    }
  }
  .checkbox-agree {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-right: 8px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background: $primary-color;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: -0.5px;
      left: 0;
      height: 14px;
      width: 14px;
      background-color: #f3f6f9;
      border-radius: 4px;
      border: 1px solid $primary-color;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.disabled-btn.btn {
  background: #e4e6ee;
  color: #8c979f;
}
.not_valis_phone_messege {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #e75f5f;
  margin-top: 10px;
}
.form-control.form-control-styles.not_valid_input {
  background: rgba(231, 95, 95, 0.1);
  color: #e75f5f;
}
.form-control.form-control-styles.not_valid_input::placeholder {
  color: #e75f5f;
}
.code_verification_input.styles_react-code-input-container__tpiKG {
  width: 388px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.part_phone_number span {
  display: block;
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #193040;
}
.resend_and_support_text {
  text-align: center;
}
.resend_and_support_links {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8c979f;
}
.code_verification_input.styles_react-code-input-container__tpiKG div {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code_verification_input.styles_react-code-input-container__tpiKG input {
  margin: 0 8.8px;
  border: none !important;
  background: #f3f6f9 !important;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #8c979f;
}
.code_verification_input.styles_react-code-input-container__tpiKG
  input:first-child {
  margin-left: 0 !important;
}

.code_verification_input.styles_react-code-input-container__tpiKG
  input:last-child {
  margin-right: 0 !important;
}

@media screen and (max-width: 576px) {
  .number_verification .w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
    padding: 25px;
    width: 100% !important;
  }
}
@media screen and (max-width: 480px) {
  .d-flex.flex-center.flex-column.flex-column-fluid.p-10.pb-lg-20 {
    padding: 25px 7px !important;
  }
  .w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
    padding: 48px 7px;
  }
  .activate_account .w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
    padding: 48px 20px;
    width: 100%;
  }
  .activate_account
    .d-flex.flex-center.flex-column.flex-column-fluid.p-10.pb-lg-20 {
    padding: 0 10px !important;
  }
  .reset_email_card .w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
    width: 100% !important;
    padding: 48px 10px;
  }
  .text-center.mb-34 {
    margin-right: 0 !important;
  }
  .number_verification .w-lg-500px.bg-white.rounded.shadow-sm.p-42 {
    margin: 0 10px !important;
  }
  .code_verification_input.styles_react-code-input-container__tpiKG {
    width: 100% !important;
  }
  .code_verification_input.styles_react-code-input-container__tpiKG div {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
  }
  .code_verification_input.styles_react-code-input-container__tpiKG input {
    margin: 0 2.5px;
  }
}
@media screen and (max-width: 380px) {
  .code_verification_input.styles_react-code-input-container__tpiKG input {
    width: 45px !important;
    height: 45px !important;
  }
}
@media screen and (max-width: 350px) {
  .code_verification_input.styles_react-code-input-container__tpiKG input {
    width: 40px !important;
    height: 40px !important;
  }
}

.not-found__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  .not-found-section {
    width: fit-content;
    height: 50vh;
    display: flex;
    align-items: flex-start;
    gap: 25px;
    justify-content: center;
    flex-direction: column;
    .not-found-icon {
      display: flex;
      justify-content: center;
      max-width: 259.65px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .not-found-title {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #193040;
      }
    }

    .not-found__search-container {
      width: 48px;
      height: 48px;
      background-color: rgb(246, 248, 250);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        fill: rgb(135, 145, 159);
      }
    }

    .not-found__content-container {
      .not-found__text {
        font-size: 14px;
        line-height: 20px;
        color: rgb(106, 115, 131);
      }
    }
  }
}

svg-icon.svg-icon-1 {
  svg {
    width: 16px !important;
    height: 16px !important;
    padding-right: 0 !important;
  }
}

.text--end {
  position: relative;
  width: 35px;
}
.dashboard__content {
  background: $white;

  &--inner {
    width: 1200px;
  }

  .dashboard-heading__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-top: 25px;
  }

  .dashboard__content--header {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $header-black;
  }
  .box {
    display: flex;
    flex-direction: row;
    gap: 42px;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
      gap: 0;
    }
    @media screen and (max-width: 576px) {
      gap: 0;
    }
    &-rows {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      flex-grow: 1;
      width: 50%;
      &:first-child {
        width: 45%;
        @media screen and (max-width: 1400px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 1400px) {
        flex-basis: 100%;
        width: 100%;
      }
    }
    &-cols {
      padding: 24px;
      background: #ffffff;
      border-radius: 24px;
      margin-bottom: 50px;

      @media screen and (max-width: 576px) {
        padding: 16px;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        @media screen and (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &-title {
          margin: 0;
          min-width: 150px;
          @media screen and (max-width: 576px) {
            margin-bottom: 10px;
          }
          h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 22px;
            color: #181c32;
          }
        }
        &-filter {
          display: flex;
          align-items: center;
          flex-direction: row;
          @media screen and (max-width: 576px) {
            width: 100%;
          }
          .box-filter-card {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: relative;
            @media screen and (max-width: 576px) {
              justify-content: space-between;
              width: 100%;
            }
            .box-filter-button {
              margin-right: 20px;
            }
          }
          .react-datepicker-wrapper {
            display: flex;
            justify-content: flex-end;
            input {
              margin-right: 0 !important;
              height: 34px;
              padding: 0;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 18px;
              color: #193040;
              padding: 0 8px;
              background-image: url("../../components/base/Icon/icons/calendar-icon.svg");
              background-repeat: no-repeat;
              background-position: right 10px center;
              background-size: 16px;
              .react-datepicker-ignore-onclickoutside {
                background: #fff;
              }
            }
            .react-datepicker__close-icon {
              margin-right: 28px;
            }
          }
          .btn {
            height: 34px;
            display: flex;
            align-items: center;
            gap: 6px;
            &.btn-grey-filter {
              background: #e4e6ee;
              color: #193040;
              svg {
                path {
                  fill: #193040;
                }
              }
            }
            &.btn-blue-filter {
              background: $primary-color;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
              svg {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
      &-content {
        .summary-card {
          display: flex;
          flex-direction: column;
          .summary-row {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            gap: 28px;
            margin-top: 16px;
            @media screen and (max-width: 576px) {
              flex-direction: column;
              margin: 0;
              gap: 0;
            }
            &:first-child {
              margin-top: 0;
              @media screen and (max-width: 576px) {
                .summary-col {
                  &:first-child {
                    margin-top: 0px;
                  }
                }
              }

              .summary-col {
                border: 0;
                &:nth-child(1) {
                  background: rgba(30, 192, 56, 0.1);
                }
                &:nth-child(2) {
                  background: rgba(242, 214, 64, 0.1);
                }
                &:nth-child(3) {
                  background: rgba(231, 95, 95, 0.1);
                }
              }
            }
            .summary-col {
              display: flex;
              flex-basis: 33.333%;
              flex-grow: 1;
              flex: 1 1 33.333%;
              padding: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background: #ffffff;
              border: 1px dashed #e4e6ee;
              box-sizing: border-box;
              border-radius: 8px;
              height: 111px;
              @media screen and (max-width: 576px) {
                flex-basis: 100%;
                flex-grow: 1;
                flex: 1 1 100%;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 10px;
              }

              &-title {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #526470;
                text-align: center;
                margin: 0;
                padding: 0;
              }
              &-sum {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 48px;
                color: #193040;
                text-align: center;
                margin: 0;
                padding: 0;
                @media screen and (max-width: 576px) {
                  font-size: 24px;
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
  .card {
    &-body {
      display: flex;
      flex-direction: row;
      gap: 50px;
      .card-rows {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        flex-grow: 1;
        &:last-child {
          flex-shrink: 0;
        }
        .card-cols {
          margin-bottom: 100px;
          .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            .react-datepicker-wrapper {
              display: flex;
              justify-content: flex-end;
              input {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
.crypto-chart {
  .apexcharts-legend {
    inset: auto 0px -2px !important;
    .apexcharts-legend-series {
      margin: 15px 5px 0;
      padding: 5px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &:nth-child(1) {
        background: #f7931a;
      }
      &:nth-child(2) {
        background: #26a17b;
      }
      &:nth-child(3) {
        background: #eab300;
      }
      .apexcharts-legend-marker {
        display: none;
      }
      .apexcharts-legend-text {
        color: #ffffff !important;
      }
    }
  }
}
.crypto-segmentation-chart {
  .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    display: flex;
    justify-content: center;
    .apexcharts-legend-series {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 !important;
      &:nth-child(2) {
        margin: 5px 0 !important;
      }
      .apexcharts-legend-marker {
        width: 10px !important;
        height: 4px !important;
        background: #000;
      }
      .apexcharts-legend-text {
        font-size: 16px !important;
        color: #8c979f !important;
        margin-left: 5px !important;
      }
    }
  }
}

.order-status-chart {
  .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
    .apexcharts-legend-series {
      margin: 10px 5px 0;
      padding: 5px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &:nth-child(1) {
        background: #01a51c;
        .apexcharts-legend-text {
          color: #ffffff !important;
        }
      }
      &:nth-child(2) {
        background: #e4e6ee;
        .apexcharts-legend-text {
          color: #193040 !important;
        }
      }
      .apexcharts-legend-marker {
        display: none;
      }
      .apexcharts-legend-text {
        font-size: 12px !important;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.clipboard-button {
  & + a {
    color: $primary-color;
  }
  svg {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    stroke: $primary-color;
  }
}

.clipboard-button svg:hover {
  transform: scale(1.2);
}

.min-w-120px {
  min-width: 120px;
}

.min-w-220px {
  min-width: 220px;
}

.min-w-230px {
  min-width: 230px;
}

.min-w-240px {
  min-width: 240px;
}

.clipboard-button {
  background: transparent;
  border: unset;
  outline: unset;
  box-shadow: unset;
}

.navbar__wrapper {
  padding-right: 40px;
  padding-left: 40px;
}

.navbar__list {
  list-style: none;
  height: 40px;
  background: #ffffff;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  border-bottom: 1px solid #ebeef1;
  padding-bottom: 5px;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.navbar__item {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 20px;
  padding: 2px 10px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  color: #30313d;

  & > svg {
    transition: all 0.3s ease-in-out;
    fill: #30313d;
  }

  & > a {
    color: #30313d;
  }

  &.active {
    background: $primary-color;
    color: $white;

    & > svg {
      fill: $white;
    }

    & a {
      color: #ffffff;
    }
  }

  &.clicked {
    & > svg {
      transform: rotate(180deg);
    }
  }

  &.flex {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.profile__wrapper {
  display: flex;
  align-items: center;

  .button {
    svg {
      fill: #545a69;
      transition: all 0.3s ease-out;
    }

    &:hover > svg {
      fill: #444052;
    }
  }

  .info__wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;

    & > span {
      margin-top: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $dark-grey-1;
    }
  }
}

// React tiny popover library styles
.profile__popover {
  z-index: 2;
  border-radius: 6px;
}

.filter__popover {
  z-index: 2;
  overflow: unset !important;
  & > div {
    padding: 0;
    & > div:first-of-type {
      border-bottom: 7px solid #fffafa !important;
    }
  }
}

.popover__content {
  min-width: 250px;
  background: #ffffff;
  position: relative;
  border: 1px solid rgba(26, 31, 54, 0.05);
  box-shadow: 0px 15px 35px rgba(60, 66, 87, 0.12),
    0px 5px 15px rgba(0, 0, 0, 0.08);
  // overflow: hidden;
  border-radius: 6px;

  .button {
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    padding: 0 10px;
    width: 100%;
    color: $primary-color;
    text-align: left;
    border-radius: 0;

    &:hover {
      color: #000000;
      background: rgba(0, 0, 0, 0.1);
    }

    &.filter--apply {
      background: $primary-color;
      border-radius: 4px;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      padding: 4px;
      width: 100%;
      border: none;
      outline: none;
    }
  }

  .profile__popover--actions-wrapper {
    padding: 10px 0px;

    .menu-item {
      padding: 0;
      box-shadow: none;
    }

    button {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
}

.tab-list__wrapper {
  .tab-list--list {
    list-style: none;
    padding: 0;
    display: flex;
    border-bottom: 1px solid #ebeef1;
    gap: 28px;
  }

  .tab-item__wrapper {
    .button {
      padding: 0;
    }

    &.active {
      border-bottom: 1px solid $primary-color;

      .tab-item {
        color: $primary-color;
      }
    }
  }

  .tab-item__wrapper {
    padding-bottom: 8px;
  }

  .tab-item {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #697386;
  }
}

.filter-dropdown {
  &__container {
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .button.filter-dropdown__button--back {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #e0e6eb;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
        0px 2px 5px rgba(60, 66, 87, 0.08);
      border-radius: 4px;
      width: 28px;
      height: 28px;
    }
  }

  &__headerline {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__header {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  &__input-wrapper {
    position: relative;

    .filter-dropdown__filter-error {
      background: #ffffff;
      padding: 8px;
      font-size: 14px;
      color: rgb(64, 68, 82);
      position: absolute;
      right: -20px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 4px;
      line-height: 1;
      top: 50%;
      transform: translate(100%, -50%);

      svg {
        position: absolute;
        left: 7px;
        top: 50%;
        transform: translate(-100%, -50%) rotate(-90deg);
        color: #fff;
      }
    }
  }
}

.input__wrapper .input.filter__dropdown--input {
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3c4257;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a3acb9;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a3acb9;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a3acb9;
  }
}

.select__container {
  position: relative;
  height: 28px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e0e6eb;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  .select__container--select {
    height: 100%;
    border: none;
    outline: none;
    width: 100%;
    user-select: auto;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #3c4257;
    cursor: pointer !important;
    /* for Firefox */
    -moz-appearance: none;
    appearance: none;
    /* for Chrome */
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  svg {
    position: absolute;
    right: 8px;
    top: 7px;
    pointer-events: none;
  }

  .select__pointer-overlay {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 0;
    border-radius: inherit;
    opacity: 0;
    pointer-events: none;
  }
}

.datepicker__wrapper {
  display: flex;
  gap: 12px;

  .datepicker__inner-block {
    display: flex;
    gap: 12px;
    align-items: center;

    &:first-of-type {
      max-width: 139px;
    }

    &:last-of-type {
      max-width: 119px;
    }
  }

  .datepicker__header {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0;
  }

  .react-datepicker {
    border: 1px solid rgba(26, 31, 54, 0.05);
    box-shadow: 0px 15px 35px rgba(60, 66, 87, 0.12),
      0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  .react-datepicker__input-container {
    & > .input__wrapper {
      width: 95px;
      height: 28px;

      input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e6eb;
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
          0px 1px 1px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
      }

      svg {
        left: unset;
        right: 8px;
        top: 8px;
      }
    }
  }

  .react-datepicker__month-container {
    width: 270px;
    padding: 8px;
  }

  .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #30313d;
  }

  .react-datepicker__navigation {
    background: #ffffff;
    border: 1px solid #e0e6eb;
    box-shadow: 0px 3px 9px rgba(60, 66, 87, 0.08),
      0px 2px 5px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 28px;
    height: 28px;
    top: 8px;

    &.react-datepicker__navigation--previous {
      left: 8px;
    }

    &.react-datepicker__navigation--next {
      right: 8px;
    }

    .react-datepicker__navigation-icon--previous {
      &::before {
        border-color: #4f566b;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }

    .react-datepicker__navigation-icon--next {
      &::before {
        border-color: #4f566b;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  .react-datepicker__header {
    background: #ffffff;
    border-bottom: unset;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day-name {
    font-size: 12px;
    line-height: 16px;
    color: #a3acba;
  }

  .react-datepicker__month {
    margin: unset;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day {
    width: 36px;
    height: 36px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: unset;
    color: #30313d;
    background: #f6f8fa;
    border: 1px solid #ebeef1;
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background: $primary-color;
    color: #ffffff;
  }
}

.filter__button-text--inner {
  color: $primary-color;
}

.filter__button-separator {
  background-color: rgba(4, 36, 68, 0.25);
  height: 12px;
  width: 1px;
}

.filter__button--remove {
  padding: 0;
  transform: translateY(-1px);
}

.filter-list__container {
  // border-bottom: 1px solid #ebeef1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;

  .button__filter--clear {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #697386;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: all 0.2s ease-in-out;

    & > svg {
      fill: #697386;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: #30313d;

      & > svg {
        fill: #30313d;
      }
    }

    &:disabled {
      color: #a3acba;

      & > svg {
        fill: #a3acba;
      }
    }
  }
}

.button--export {
  display: block;
  background: #ffffff;
  border: 1px solid #e0e6eb;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 2px 5px rgba(60, 66, 87, 0.08);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    fill: #30313d;
  }

  &:disabled {
    svg {
      fill: rgb(224, 230, 235);
    }
  }
}

.number-range__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  .number-range__inner-block {
    display: flex;
    align-items: center;
    gap: 12px;

    .number-range__header {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: unset;
    }

    .number-range__input {
      max-width: 77px;
      background: #ffffff;
      border: 1px solid #e0e6eb;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
        0px 1px 1px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      height: 28px;
      padding-left: 10px;
      transition: all 0.3s ease-in-out;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }
  }
}

.filter-buttons__container {
  display: flex;

  .button__filter--add {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $primary-color;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.3s ease-in-out;

    svg {
      fill: $primary-color;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      color: #1a1b25;

      svg {
        fill: #1a1b25;
      }
    }
  }
}

.add-filter__container {
  padding: 12px 10px;
  .add-filter__list {
    margin: unset;
    padding: unset;
  }

  .add-filter__list-item {
    margin-bottom: 8px;
    .button {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: $primary-color;
      padding: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: transparent;
        color: #1a1b25;

        svg {
          fill: #1a1b25;
        }
      }

      &:disabled {
        color: #a3acba;
        pointer-events: none;
        background: transparent;

        svg {
          fill: #a3acba;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      fill: $primary-color;
      transition: all 0.3s ease-in-out;
    }
  }
}

.auth-logo__container {
  margin-right: auto;

  svg {
    width: 105px;
  }
}

.auth__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px !important;

  .auth-content__wrapper {
    width: fit-content;
  }

  .auth__form {
    h1 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
    }

    label {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px;
      color: #000 !important;
    }

    input.tfa-input.form-control.form-control-solid {
      background-color: transparent !important;
      border: 1px solid #ebeef1;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &.error {
        border-color: #e75f5f;
        color: #e75f5f !important;
      }
    }

    a {
      color: $primary-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .error-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #e75f5f;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.invalid-button {
  background-color: #dbdbdb !important;
  color: #8c979f !important;
  pointer-events: none;
}

.text-left {
  text-align: left;
}

.button-export__wrapper {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;

  & > button:first-of-type > svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.order-id {
  display: flex;
  align-items: center;
  border: none;
  a {
    color: $primary-color !important;
    text-decoration: none !important;
  }
}

.tooltip__outer-wrapper {
  position: relative;

  .tooltip__inner-container {
    position: absolute;
    top: -90%;
    left: -25%;
    background: #fff;
    padding: 6px;
    background: #ffffff;
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    filter: drop-shadow(0px 15px 35px rgba(60, 66, 87, 0.12))
      drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.08));

    svg {
      width: 20px;
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .tooltip__content {
      margin: unset;
    }
  }
}

.badge__wrapper {
  border-radius: 4px;
  padding: 1px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: fit-content;

  &.info {
    background: $info-background;
    color: $info-color-2;
  }
}

#kt_header_container {
  background: $light-grey;
}

#kt_header {
  .container {
    width: 100%;
  }
}

.banner__wrapper {
  margin-top: 36px;
  background: $light-grey;
  border: 1px solid $light-grey-2;
  border-radius: 4px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  position: relative;
  gap: 15px;

  & > .button:first-of-type {
    margin-bottom: 4px;
    padding: 0;
  }

  & > .close-button {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  .dashboard__banner--content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $header-black;

    .banner-button {
      color: $primary-color;
      padding: 0;
    }
  }
}

.dashboard__grid-container {
  margin-top: 38px;
  .dashboard__grid-heading-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
  }

  .dashboard__grid-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $header-black;
  }

  .dashboard__grid-count {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
  }

  .dashboard__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .dashboard-item {
      min-height: 99px;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $light-grey-2;
      border-right: none;

      &:first-of-type {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-right: 1px solid $light-grey-2;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }

      &--title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dark-grey;
        margin-bottom: 3px;
      }

      &--value {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $header-black;
        letter-spacing: 0.340402px;
      }
    }
  }

  &.partners {
    margin-top: unset;
    .dashboard-item {
      border-left: none !important;
      border-right: none !important;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-bottom: none;
        border-top: none;
      }

      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8) {
        border-top: none;
      }
    }
  }
}

.pie-chart__container {
  width: 280px;
  transform: translateX(-15%);
  z-index: 2;
}

.chart-tooltip__outer-container {
  background: transparent !important;
  padding-bottom: 10px;

  svg {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
  }

  .chart-tooltip__container {
    position: relative;
    background: $white;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $light-grey-3;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
  }
}

.apexcharts-tooltip {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  top: -10px !important;
  justify-content: center !important;
}

.pie-chart__total-value {
  font-size: 10px;
}

.pie-chart__flex-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.dashboard-pie-chart__wrapper {
  flex: 1;
  padding: 30px 24px;
  border: 1px solid $light-grey-2;

  .dashboard-pie-chart__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $header-black;
    margin-bottom: 30px;
  }

  &:last-of-type {
    border-left: none;
  }

  .dashboard-pie-chart__content-wrapper {
    display: flex;
    align-items: center;
  }
}

.dashboard-pie-chart__legend-container {
  &--orders {
    display: flex;
    flex-direction: column;
    transform: translateX(-20%);
    .pie-legend {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: $dark-grey;
      margin-bottom: 16px;
      white-space: nowrap;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
      }

      &:first-of-type::before {
        background: $chart-green;
      }

      &:nth-of-type(2):before {
        background: $chart-blue;
      }

      &:last-of-type:before {
        background: $chart-red;
      }
    }
  }

  &--countries {
    display: flex;
    flex-direction: column;
    transform: translateX(-20%);
    .pie-legend {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: $dark-grey;
      margin-bottom: 16px;
      white-space: nowrap;
      & > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        opacity: 0.5;
        margin-left: 4px;
      }
      & > img {
        width: 14px;
        margin-right: 10px;
      }
    }
  }
}

.pie-legend {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey;

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
    margin-left: 4px;
  }

  & > img {
    width: 14px;
    margin-right: 10px;
  }
}

.users-grid__skeleton-container {
  width: 100%;
  margin-top: 36px;
}

.pie-chart__skeleton-container {
  width: 50%;
  height: 328px;
}

.footer {
  margin-top: auto;
}

.clients--tabs {
  .partner--row {
    padding-top: 6px;
  }
}

.filter-skeleton__wrapper {
  display: flex;
  gap: 20px;
}

.log-table-id {
  & > div {
    display: flex;
    align-items: center;
  }
}

.drawer {
  // &__container {
  //   min-height: 100vh;
  //   background: transparent;
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   z-index: 2;
  //   width: 100%;
  //   display: block;

  //   &.inactive {
  //     display: none;
  //   }
  // }

  &__inner-block {
    z-index: 2;
    position: fixed;
    min-height: 100vh;
    width: 320px;
    background: #ffffff;
    box-shadow: 0px 50px 100px rgba(60, 66, 87, 0.08),
      0px 15px 35px rgba(60, 66, 87, 0.08), 0px 5px 15px rgba(0, 0, 0, 0.12);
    border-radius: 8px 0px 0px 8px;
    top: 0;
    right: 0;
    transition: right 0.3s cubic-bezier(0.82, 0.085, 0.395, 0.895);

    &.inactive {
      right: -320px;
    }
  }
}

.ReactModal__Content {
  padding: 0 !important;
  background: #f7f8fa;
  box-shadow: 0px 50px 100px rgba(60, 66, 87, 0.08),
    0px 15px 35px rgba(60, 66, 87, 0.08), 0px 5px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
}

.auth-form__container {
  max-width: 484px;
  padding: 36px !important;

  #kt_sign_in_submit {
    padding: 14px 0 !important;
    border-radius: 4px;
    height: 50px;
  }
}

.tr-status {
  padding: 0px 6px;
  background: $light-grey-2;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $dark-grey;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 12px;
    height: 12px;
    fill: #6a7383;
  }
}

.popover-stats__container {
  .popover-stats__title {
    margin: 8px 10px auto 10px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: $black-blue;
    text-transform: uppercase;
    letter-spacing: 0.340402px;
  }

  .popover-stats__container--radio {
    padding: 18px 11px;

    .radio__wrapper {
      margin-bottom: 8px;
      .radio__label {
        display: block;
        padding-left: 25px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $header-black;
        position: relative;

        &:hover input ~ .radio__checkmark {
          background: rgba(0, 0, 0, 0.1);
        }

        & > input {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked {
            & ~ .radio__checkmark {
              background: $chart-blue;

              &:after {
                display: block;
              }
            }
          }
        }

        .radio__checkmark {
          position: absolute;
          top: 50%;
          left: 0;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background: #ffffff;
          border: 1px solid #c0c8d2;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
            0px 1px 1px rgba(0, 0, 0, 0.08);
          transform: translateY(-50%);

          &:after {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background: $white;
            top: 50%;
            left: 50%;
            display: none;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.radio-disabled {
          opacity: 0.5;
        }
      }

      .radio-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    & > input[type="number"] {
      width: 100%;
      border: 1px solid #e3e8ee;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #a3acb9;
      padding-left: 8px;
      outline: none;
    }
  }

  .popover-stats__container--textarea {
    margin: 16px 10px;

    & > textarea {
      width: 100%;
      height: 104px;
      padding: 4px 8px;
      border: 1px solid $light-grey-3;
      border-radius: 4px;
    }
  }

  .radio-buttons__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    padding: 8px;
    padding-top: 0;

    .button {
      padding: 4px 8px;
      border-radius: 4px;
      width: fit-content;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 16px;
    }

    .button-cancel {
      background: $white;
      border: 1px solid #e0e6eb;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
        0px 2px 5px rgba(60, 66, 87, 0.08) !important;
      color: $dark-gray-2;
      padding: 4px 8px;
      line-height: 16px !important;
    }

    .button-submit {
      background: $primary-color;
      color: $white;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      outline: none;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12),
        0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12) !important;
      border-radius: 4px;
      border: 1px solid $primary-color;
      padding: 4px 8px !important;

      &:disabled {
        cursor: none;
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

.filter-list-skeleton__wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  min-height: 62px;
  border-bottom: 1px solid #ebeef1;
}

.filter-list__items-wrapper {
  & > button {
    padding: 0;
    margin: 9px;
  }
}

.reports-container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-top: 25px;

  & > .reports__inner-block {
    width: 270px;
    height: 110px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(23, 29, 50, 0.25);
    border-radius: 12px;

    & > div {
      padding: 20px;
      height: 100%;
      align-items: center;
    }

    .box-header {
      &-summary {
        font-weight: 700;
        font-size: 30px;
        line-height: 47px;
        color: $main-black;
      }

      &-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $light-grey-4;
      }
    }
  }

  .react-datepicker__input-container {
    & > input {
      width: 126px !important;
    }
  }
}

.user-status-active {
  background: #d7f7c2;
  color: #05690d;
  padding: 0 6px;
}

.user-status-process {
  background: rgba(32, 175, 245, 0.15);
  color: rgb(32, 175, 245);

  & > svg {
    fill: rgb(32, 175, 245);
  }
}
.user-status-info {
  background: #cff5f6;
  color: #0055bc;

  & > svg {
    fill: #0055bc;
  }
}
.user-status-blocked {
  background: #ffe7f2;
  color: #b3063d;
}
.user-status-pending {
  background: rgba(255, 191, 10, 0.25);
  color: #be9623;
}
.user-status-panding {
  background: rgba(255, 191, 10, 0.25);
  color: #be9623;

  & > svg {
    fill: #be9623;
  }
}

.notifications__wrapper {
  width: 400px;

  .notifications__header {
    &-wrapper {
      padding: 20px 24px;
      border-bottom: 1px solid $light-grey-2;
    }

    &-content {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $black;

      & > .notification-count {
        font-weight: 700;
        color: $text-grey;
      }
    }
  }

  .notifications__list {
    list-style: none;
    height: 612px;
    overflow-y: scroll;
    position: relative;
    li {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 14px 24px;

      &.unwatched:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: red;
        position: absolute;
        left: 7px;
      }

      .intersection__block {
        height: 5px;
        width: 100%;
        background: red;
      }

      .icon__wrapper {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          width: 16px;
          height: 16px;
        }

        &.user-status {
          &-panding {
            background: $pending-color;
          }

          &-info {
            background: $info-color;
          }

          &-active {
            background: $success-color;
          }

          &-blocked {
            background: $fail-color;
          }
        }
      }

      .tr-status__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & > span:first-of-type {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $header-black;
          margin-bottom: 4px;
        }

        &:last-of-type {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $dark-grey;
        }
      }
    }
  }
}

.notification-button__wrapper {
  position: relative;

  .notification-count__wrapper {
    min-width: 20px;
    padding: 10%;
    border-radius: 50%;
    background: $chart-red;
    position: absolute;
    transform: translate(15px, 10%);
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    color: $white;
    border: 2px solid $white;
  }
}

.zero-notification {
  &__wrapper {
    padding: 24px;
  }

  &__content {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-grey;
  }
}

input.password-input::-webkit-credentials-auto-fill-button {
  margin-right: 10px;
}

.tier-badge {
  min-width: 46px;
  min-height: 18px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #6a7383;
  padding: 1px 6px;
  border-radius: 4px;
  background: #ebeef1;
}

.tier-limit {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a7383;
}

.id-wrapper {
  display: flex;
  align-items: center;
}

.add-crypto-button {
  display: block;
  margin-left: auto;
}

.add-crypto-error {
  position: absolute;
  bottom: -20px;
}

.balance-banner {
  &__container {
    width: 100%;
    padding: 16px 25px;
    border: 1px solid $light-grey-2;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}

.balance-info {
  &__container {
    display: flex;
    gap: 16px;
  }

  &__content {
    & > h1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-grey;
    }

    & > span {
      & > span:first-of-type {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: $header-black;
      }

      & > span:last-of-type {
        margin-left: 5px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $dark-grey;
      }
    }
  }
}

.balance-headline {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    h1 {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      color: $header-black;
    }
  }
}

.balance-wallet {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .balance-qr {
      &__container {
        width: 48px;
        height: 48px;
        padding: 3px;
        border-radius: 5px;
        border: 1px solid $light-grey-2;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__content {
    & > h1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-grey;
      margin-bottom: 4px;
    }

    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $header-black;
    }
  }

  &__address-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .clipboard-gray {
      padding-top: 5px;
      padding-bottom: 0;
      svg {
        stroke: $text-grey;
        width: 14px;
        height: 14px;
      }
    }
  }

  &__qr-trigger {
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
  }
}

.qr-popup {
  &__container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
    align-items: center;
    justify-content: center;
    transition: all 0.43s ease-in-out;
    border: none;
    outline: none;

    &.active {
      display: flex;
      cursor: unset;
    }
  }

  &__wrapper {
    background: $white;
    width: 357px;
    border-radius: 4px;
  }

  &__headline {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey-2;

    & > h1 {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $header-black;
    }
  }

  &__close-button {
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
    position: relative;

    &:hover {
      & > span {
        &:first-of-type {
          transform: rotate(45deg) scale(1.2);
        }
        &:last-of-type {
          transform: rotate(-45deg) scale(1.2);
        }
      }
    }

    & > span {
      height: 14px;
      width: 2px;
      top: 2px;
      background: $text-grey;
      display: block;
      position: absolute;
      transition: all 0.43s ease-in-out;

      &:first-of-type {
        transform: rotate(45deg);
      }
      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }

  &__inner-wrapper {
    padding: 44px;
    display: flex;
    justify-content: center;
  }

  &__content-wrapper {
    padding: 0 30px 24px 30px;
    text-align: left;
    & > h1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-grey;
      margin-bottom: 4px;
    }

    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $header-black;
    }
  }

  &__address-wrapper {
    display: flex;
    align-items: center;

    .clipboard-gray {
      padding-top: 5px;
      padding-bottom: 0;

      svg {
        stroke: $text-grey;
        width: 14px;
        height: 14px;
      }
    }
  }
}

.payout-header {
  &__container {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid $light-grey-2;
    display: flex;
    align-items: center;

    .button:first-of-type {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
      position: relative;
      margin-right: 20px;

      &:hover {
        & > span {
          &:first-of-type {
            transform: rotate(45deg) scale(1.2);
          }
          &:last-of-type {
            transform: rotate(-45deg) scale(1.2);
          }
        }
      }

      & > span {
        position: absolute;
        width: 2px;
        height: 13px;
        background: $dark-gray-3;
        top: 3px;
        transition: all 0.43s ease-in-out;

        &:first-of-type {
          transform: rotate(45deg);
        }
        &:last-of-type {
          transform: rotate(-45deg);
        }
      }
    }

    .button-primary {
      margin-left: auto;
      padding: 4px 8px;
    }
  }

  &__text {
    margin-left: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $header-black;
    }

    &:before {
      content: "";
      height: 24px;
      width: 1px;
      background: $dark-gray-3;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
    }
  }
}

.payout__container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.payout-form {
  &__wrapper {
    width: 500px;
    height: 380px;
    padding: 20px;
    background: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > h1 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: $header-black;
      text-align: center;
      margin-bottom: 24px;
    }

    form {
      .form-group {
        margin-bottom: 24px;
        position: relative;
        label {
          display: block;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          letter-spacing: -0.154px;
          color: $dark-gray-2;
          margin-bottom: 4px;
        }

        input {
          width: 100%;
          height: 28px;
          border-radius: 4px;
          border: 1px solid $light-grey-3;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $header-black;
          padding: 4px 45px 4px 8px;

          &::placeholder {
            color: $dark-gray-4;
          }
        }

        .payout-button {
          background: transparent;
          outline: none;
          border: none;
          width: fit-content;
          padding: 0;
          box-shadow: none;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $primary-color;
          position: absolute;
          top: 28px;
          right: 8px;
        }
      }
    }
  }

  &__total-amount {
    & > span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $header-black;

      & > p {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $header-black;
        letter-spacing: -0.4px;
        margin-left: 8px;
        display: inline-block;
      }
    }
  }
}

.payout-banner {
  &__wrapper {
    background: $banner-background;
    border: 1px solid $banner-border;
    border-radius: 4px;
    padding: 16px;
    position: relative;
    margin-bottom: 24px;

    .button {
      background: transparent;
      outline: none;
      border: none;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      box-shadow: none;

      & > span {
        width: 2px;
        height: 15.9px;
        position: absolute;
        background: $dark-gray-3;
        display: inline-block;
        top: 2px;
        left: 8px;

        &:first-of-type {
          transform: rotate(45deg);
        }
        &:last-of-type {
          transform: rotate(-45deg);
        }
      }
    }

    svg {
      position: absolute;
      top: 20px;
      left: 16px;
      width: 12px;
      height: 12px;
    }
  }

  &__content {
    max-width: 335px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: $dark-grey;
    margin-left: 40px;
  }
}

.payout-otp {
  &__form-wrapper {
    width: 500px;
    height: 295px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    gap: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: $header-black;
    }

    .payout-opt__form {
      width: fit-content !important;

      &.invalid-code {
        & > .styles_react-code-input__CRulA {
          & > input {
            border: 1px solid $error-color !important;
            color: $error-color !important;

            // &:placeholder-shown {
            //   background: $light-grey-5;
            //   height: 100%;
            //   width: 100%;
            // }

            // &::placeholder {
            //   color: rgba($black2, 0.3);
            // }
          }
        }
      }
    }

    .styles_react-code-input__CRulA {
      display: flex;
      gap: 8px;
      & > input {
        width: 40px !important;
        height: 40px !important;
        border: 1px solid $light-grey-2 !important;
        border-radius: 4px !important;
        font-family: "SF-Pro", Helvetica, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        color: $black2 !important;
        caret-color: $black2 !important;

        &:placeholder-shown {
          background: $light-grey-5;
          height: 100%;
          width: 100%;
        }

        &::placeholder {
          color: rgba($black2, 0.3);
        }
      }
      // & > input:first-child {
      //   border-top-left-radius: 0 !important;
      //   border-bottom-left-radius: 0 !important;
      // }

      // & > input:last-child {
      //   border-top-right-radius: 0 !important;
      //   border-bottom-right-radius: 0 !important;
      // }
    }

    .otp-attempts {
      font-size: 14px;
      font-weight: 400;
      color: #c84801;
      margin-bottom: 24px;
    }
  }

  &__form-content {
    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-grey;
      text-align: center;
      margin-bottom: 28px;

      & > span {
        font-weight: bold;
      }
    }

    .button {
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.154px;
      padding: 0;

      &:disabled {
        opacity: 0.5;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__form-outer-block {
    position: relative;
  }

  &__error-message {
    color: $error-color;
    display: block;
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}

.payout__message-wrapper {
  width: 500px;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > svg {
    width: 32px;
    height: 32px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $dark-grey;
    text-align: center;
    max-width: 320px;
  }
  .button {
    background: $white;
    border: 1px solid $banner-border;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
      0px 2px 5px rgba(60, 66, 87, 0.08);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $dark-gray-2;
    padding: 4px 8px;
  }
}

.balance-empty {
  &__container {
    height: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 215px;

    & > h1 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $black2;
      margin-bottom: 4px;
    }

    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $dark-grey;
      text-align: center;
      max-width: 300px;
    }
  }

  &__logo-wrapper {
    display: block;
    width: 44px;
    min-height: 44px;
    border-radius: 4px;
    background: $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.nav-dropdown {
  &__container {
    padding: 12px 10px;
    border: 1px solid rgba(26, 31, 54, 0.05);
    box-shadow: 0px 15px 35px rgba(60, 66, 87, 0.12),
      0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  &__list {
    padding: 0;
    &-item {
      opacity: 0.8;

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
      & > button {
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $primary-color;
      }
    }
  }
}

.page-title {
  margin-right: auto;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 10px;
  color: #30313d;
  letter-spacing: 0.340402px;
}
.logs-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  row-gap: 16px;
  .title {
    font-weight: 700;
    font-size: 20px;
  }
  .body {
    display: flex;
    column-gap: 20px;
    .time {
      color: #a3acba;
      font-size: 14px;
    }
    .description {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.logs {
  padding: 1rem;
  .logs-question {
    padding: 1rem;
    min-width: fit-content;
    color: #a3acba;
    font-size: 14px;
    text-align: start;
    padding-left: 0;
  }
  .logs-answer {
    padding-left: 0;
    width: 85%;
    padding: 1rem;
    font-weight: 600;
  }
}
