.card-details{
    // padding: 24px 24px 0;

    &-header{
        display: flex;
        align-items: center;
        &-content{
            // margin-left: 16px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            &-body{
                display: flex;
                align-items: center;
                flex-direction: row;
                &-name{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: 0.340402px;
                    color: #193040;
                }
                &-status{
                    border: none;
                    padding: 1px 8px;
                    border-radius: 5px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    margin-left: 8px;

                    &.active{
                        background: #D7F7C2;
                        color: #05690D;
                    }
                    &.blocked{
                        background: rgba(231, 95, 95, 0.2);
                        color: #E75F5F;
                    }
                }
            }
            
            &-id{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #A3ACBA;
                margin-top: 8px;

                a{
                    margin-left: 10px;
                }
            }
        }

        .button-export__wrapper {
            margin-left: auto;
        }
    }
    &-tabs{
        margin-top: 28px;
        border-bottom: 1px solid #EBEEF1;
        // margin-left: 66px;

        .tabs-button{
            padding:0 0 11px 0;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #697386;
            border: none;
            background: none;
            margin-right: 24px;

            &-active{
                color: #2075F5;
                border-bottom: 2px solid #2075F5;
            }
        }
    }

}
.card-list{
    // padding: 24px;
   &-header{
       &-row{
           display: flex;
           flex-direction: row;
           align-items: center;
           justify-content: flex-start;

           &-col{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 8px 0px 8px 8px;
                margin-right: 24px;
                background: #F3F6F9;
                border: 1px dashed #E4E6EE;
                width: 148px;
                height: 81px;
                span{
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #8C979F;
                    letter-spacing: 0.02px;
                }
                b{
                    margin-top: 8px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #193040;
                }
            }
       }
   } 
   &-content{
       
       &-row{
            margin-top: 12px;
            display: flex;
            flex-direction: row;

            &:first-of-type {
                margin-top: unset;
            }

            &-title{
                margin-right: 10px;
                min-width: 160px;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                color: #697386;
            }
            &-value{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #30313D;
                .d--flex{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    .verification-col{
                        display: flex;
                        align-self: center;
                        span, a{
                            display: inline-block;
                            margin-left: 6px;
                            line-height: 17px;
                            box-sizing: border-box;
                        }
                        img{
                            width: 17px;
                            height: 17px;
                        }
                    }
                }

                .grey-text{
                    color: #8C979F;
                    &.ml-10{
                        display: inline-block;
                        margin-left: 15px;
                    }
                }
                &.blue-text{
                    color: #2075F5;
                    cursor: pointer;
                }
                .lightblue-text{
                    color: #2075F5;
                    text-decoration: underline;
                }
                .ml-5{
                    margin-left: 16px;
                    color: #193040;
                }

                a {
                    color: #2075F5 !important;
                    &:hover {
                        color: rgba(#2075F5, 0.8) !important;
                    }
                }
            }
       }
   }
}