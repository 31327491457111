$primary-color: #2075f5;
$header-black: #30313d;
$black: #000000;
$black2: #1d1d1d;
$info-background: #cff5f6;
$info-color-2: #0055bc;
$light-grey: #f6f8fa;
$light-grey-2: #ebeef1;
$light-grey-3: #e3e8ee;
$light-grey-4: #c5cbcf;
$light-grey-5: #fafafa;
$text-grey: #a3acba;
$white: #ffffff;
$dark-grey: #6a7383;
$dark-grey-1: #545a69;
$dark-gray-2: #3c4257;
$dark-gray-3: #8792a2;
$dark-gray-4: #a3acb9;
$chart-green: #228403;
$chart-blue: #0570de;
$chart-red: #df1b41;
$error-color: #df1b41;
$black-blue: #1a1f36;
$main-black: #193040;
$pending-color: #fcedb9;
$info-color: #cff5f6;
$fail-color: #ffe7f2;
$success-color: #d7f7c2;
$banner-background: #fef9da;
$banner-border: #e0e6eb;
