.date-filter-left {
  color: #6a7383;
  border: 1px solid #3c42571f;
  border-radius: 4px;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  box-shadow: 0px 1px 1px 0px #00000014;
  box-shadow: 0px 2px 5px 0px #3c42571f;
  box-shadow: 0px -1px 1px 0px #0000001f inset;
  padding: 4px 8px;
  margin-right: -0.7rem;
}
.date-filter-right {
  color: #6a7383;
  border: 1px solid #3c42571f;
  border-radius: 4px;
  // border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  box-shadow: 0px 1px 1px 0px #00000014;
  box-shadow: 0px 2px 5px 0px #3c42571f;
  box-shadow: 0px -1px 1px 0px #0000001f inset;
  padding: 4px 8px;
  margin-left: -0.7rem;
}
.overflow-visible {
  overflow: visible !important;
}
.max-width {
  width: max-content;
}
.filter-option {
  display: flex;
  column-gap: 0.7rem;
  color: #6a7383;
  border: 1px solid #3c42571f;
  border-radius: 4px;
  & > span {
    cursor: pointer;
  }
  box-shadow: 0px 1px 1px 0px #00000014;
  box-shadow: 0px 2px 5px 0px #3c42571f;
  box-shadow: 0px -1px 1px 0px #0000001f inset;
  height: fit-content;
  padding: 8px 8px;
  margin-top: 1rem;
  .active {
    color: #2075f5;
    font-weight: 600;
  }
}
