.promotions-container {
  overflow: scroll;
  height: 100%;
  .promotions-table {
    .promotion-id {
      color: #2075f5;
      font-weight: 600;
      cursor: pointer;
    }
    display: block;
    width: 0;
    font-size: 0.9rem;
    tr {
      text-wrap: nowrap;
    }
    td,
    th {
      border-bottom: 1px solid #dddddd;
      text-align: left;
      padding: 1rem;
      text-align: center;
    }
    th {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.promotion-active {
  background-color: #d7f7c2;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  color: #05690d;
}

.promotion-inactive {
  background: #ffe7f2;
  color: #b3063d;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
}
.promotion-edit {
  background: #2075f5;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
}
.promotion-disabled {
  pointer-events: none;
  background: #4b55649f;
}
