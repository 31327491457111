.order--header-button {
  &.sell-flow {
    margin-left: auto;
  }
  button {
    height: 43px;
    padding: 0;
    margin-right: 24px;
  }
}
.flows-switcher {
  width: 100%;
  display: flex;
  gap: 0 1.5rem;
}
.modal-content-container {
  width: 480px;
  max-width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  // margin-top: 55px;
  position: relative;
  .modal-header {
    position: sticky;
    top: -4;
    background-color: #fff;
    z-index: 999;
    padding: 0;
    border: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding: 16px 24px;
  }
  .modal-body {
    padding: 0 !important;
    .modal-body-content {
      background: #f7f8fa;
      padding: 16px 24px;
      .modal-content-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        &:last-of-type {
          .modal-content-col {
            margin-bottom: unset;
          }
        }

        .modal-content-col {
          width: 100%;
          margin-bottom: 24px;
          position: relative;

          &.ml-1 {
            margin-left: 24px;
          }

          label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #3c4257;
            margin-bottom: 4px;

            &:after {
              content: "*";
              color: red;
              margin-left: 5px;
            }
          }

          & > input:read-only {
            opacity: 0.5;
          }

          // &.negative_markup {
          //   label:after {
          //     content: "" !important;
          //   }
          // }
        }

        // &:nth-of-type(7) {
        //   .modal-content-col {
        //     label {
        //       &:after {
        //         content: "" !important;
        //       }
        //     }
        //   }
        // }

        // &:nth-of-type(7),
        // &:nth-of-type(9),
        // &:nth-of-type(10),
        // &:last-of-type {
        //   label:after {
        //     content: "";
        //   }
        // }
      }

      .input-error-message {
        margin-top: 5px;
      }
    }
  }
  .modal-footer {
    padding: 0 !important;
    border-top: none !important;
    padding: 16px 24px !important;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &.order-details-modal {
    padding: 0;
    margin-top: 0;

    .container {
      padding: 0;

      .flex-equal-order {
        width: 100%;
        table {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &.add-crypto-modal {
    // .modal-content-col {
    form
      > .modal-body
      > .modal-body-content
      > .modal-content-row
      > .modal-content-col
      > label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3c4257;
      margin-bottom: 4px;

      &:after {
        content: "*";
        color: red;
        margin-left: 5px;
      }
    }
    // }
  }
}

@media (max-width: 992.5px) {
  .modal-content-container {
    width: 100% !important;
  }
}

@media (max-width: 265px) {
  .form-control.form-control-solid.w-250px.ps-14 {
    width: 150px !important;
  }
}

@media (max-width: 335px) {
  .btn.btn-primary {
    margin-left: 55px;
  }
}

@media (max-width: 320px) {
  .btn.btn-primary {
    margin-left: 25px;
  }

  .d-flex.align-items-center.position-relative.my-1 {
    width: 154px !important;
  }
}

@media (max-width: 280px) {
  .d-flex.align-items-center.position-relative.my-1 {
    width: 135px !important;
  }
}

.form-control {
  &.form-control-solid {
    background: #ffffff;
    border: 1px solid #e3e8ee;
    border-radius: 4px;
  }
}
