.delete-user {
  padding: 1rem;
  display: flex;
  border-radius: 8px;

  border: 1px solid #1a1f360d;
  column-gap: 0.5rem;
  background-color: #fff;
  cursor: pointer;
  transition: all 100ms ease-in;
  &:hover {
    background-color: rgb(243, 240, 240);
  }
  color: red;
  font-size: 14px;
  font-weight: 500;
}
