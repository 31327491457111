.input-container {
    position: relative;

    span {
        position: absolute;
        width: 15px;
        height: 11px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        img {
            height: 11px !important;
            width: 15px !important;
        }
    }
}