@keyframes spin {
  from { transform: rotate(1deg)}
  to {transform: rotate(360deg)}
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
  margin: 0 20px;
  border-radius: 0.85rem;
  p {
    padding-top: 5px;
    color: #a1a5b7;
    font-weight: 600;
  }
  .loading-icon {
    animation: spin 3s linear infinite;
  }
}

