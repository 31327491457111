.webhooks-empty {
  &__container {
    &--inner {
      margin-top: 50px;
    }

    &--outer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(65, 69, 82);
    font-weight: 700;
  }

  &__text {
    text-align: center;
    font-size: 16px;
    max-width: 440px;
    margin-bottom: 20px;
    line-height: 24px;
  }

  &__button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    outline: none;
    border: none;
    background: #2075f5;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 7px;
    font-size: 16px;
  }
}
