.tfa-input,
.tfa-input.form-control.form-control-solid {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #8c979f !important;
  background-color: #ffffff;
  border: 1px solid #EBEEF1;
  border-radius: 4px;  

  &.tfa-input-error {
    background: rgba(231, 95, 95, 0.1);
    border-color: transparent;
    color: #e75f5f !important;

    &::placeholder {
      color: #e75f5f !important;
    }
  }
}
.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-welcome {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #8c979f;
  }
}
.tfa-wrapper {
  background: #f3f6f9 !important;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .tfa-content {
    max-width: 484px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: center;
    min-height: 100%;
    .tfa-header {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
    }
    .tfa-body {
      .tfa-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 36px;
        background: #ffffff;
        box-shadow: 0px 0px 16px rgba(23, 29, 50, 0.05);
        border-radius: 4px;
        &-header {
          width: 100%;
          margin-bottom: 34px;
          &-title {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            margin: 0;
            text-align: center;
          }
          &-description {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #8c979f;
            margin: 10px 0 0 0;
            a {
              margin-left: 8px;
              color: $primary-color;
            }
            .description-number {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              color: #193040;
            }
            .description-error {
              color: #e75f5f;
            }
          }
          .verified-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 34px;

            & > img {
              width: 100px;
              height: 100px;
            }
          }
        }
        &-body {
          width: 100%;
          .tfa-card-row {
            .col-forgot-password {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
            }
            &-title {
              font-style: normal;
              font-size: 14px;
              line-height: 16px;
              color: #000000;
              margin-bottom: 10px;
            }
            &-validity {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              color: #c5cbcf;
              margin-top: 10px;
              display: inline-block;
            }
            &-error-messege {
              margin: 10px 0 0 0;
              height: auto;
            }

            .steps-validity {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              margin-top: 10px;
              gap: 10px;

              .step {
                background: #f3f6f9;
                border-radius: 10px;
                height: 3px;
                width: 100%;

                &.step-error {
                  background: #e75f5f;
                }
                &.step-success {
                  background: $primary-color;
                }

                &.ml-0 {
                  margin-left: 0;
                }
                &.mr-0 {
                  margin-right: 0;
                }
              }
            }

            &.mt-40 {
              margin-top: 40px;
            }
            .styles_react-code-input-container__tpiKG {
              &.phone-number-verfication {
                width: 100% !important;
                .styles_react-code-input__CRulA {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  input {
                    border: 0;
                    padding: 10px;
                    background: #ffffff;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px;
                    font-family: inherit;
                    display: flex;
                    align-items: center;
                    color: #8c979f;
                    width: 50px !important;
                    height: 50px !important;
                    max-width: 50px;
                    border: 1px solid #EBEEF1;
                  }
                }
                &.tfa-input-error {
                  input {
                    background: rgba(231, 95, 95, 0.1);
                    color: #e75f5f !important;
                    border: none;
                  }
                }
              }
            }
          }
        }
        &-timer {
          margin-top: 32px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #193040;
        }
        &-image-error {
          max-width: 249px;
          width: 100%;
          height: 251px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-footer {
          margin-top: 34px;
          width: 100%;

          &-link {
            margin-bottom: 34px;
            label {
              display: flex;
              align-items: center;
              .form-check-input.user-list-checkbox {
                margin-right: 0px;
                background-size: 10px;
                width: 14px;
                height: 14px;
                border-radius: 4px;
              }
              .form-check-input.user-list-checkbox:checked {
                background-color: $primary-color;
                border-color: $primary-color;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #8c979f;
                margin-left: 8px;
              }
            }
            p {
              margin: 0;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              color: #8c979f;
              &.text--dark {
                color: #193040;
              }
            }
          }
        }
      }
    }
  }
  .tfa-footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 54px;

    &-text {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #8c979f !important;
      width: 100%;
      margin: 0 0 30px 0;
    }
  }
}


.tfa-card-footer-btn {
  & > button {
    min-height: 50px;
  }
}