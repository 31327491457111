@import "../../../assets/styles/variables";

.card--client-details {
  padding: 24px;
  background: #ffffff;
  border-radius: 0.85rem;
  .row-cd {
    display: flex;
    flex-direction: row;
    gap: 40px;

    .cd--info {
      min-width: 318px;
      &-header {
        display: flex;
        flex-direction: column;
        h3 {
          // margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
          letter-spacing: 0.340402px;
          margin-bottom: 24px;
        }
        span {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.154px;
            color: #697386;
          }
        }
        .cd--info-row {
          margin: 16px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          &-title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.154px;
            color: #30313d;
          }
          &-value {
            display: flex;
          }
        }
      }
      &-body {
        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #30313d;
        }

        &-content {
          .cd--info-body-row {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 12px;
            &:first-child {
              margin-top: 0;
            }
            .cd--info-body-col {
              font-size: 14px;
              line-height: 20px;
              font-style: normal;

              &-title {
                font-weight: 400;
                letter-spacing: -0.154px;
                color: #697386;
              }
              &-value {
                font-weight: 600;
                color: #30313d;
                a {
                  color: $primary-color;
                  font-weight: 400;
                }
                &-badge {
                  padding: 1px 6px;
                  background: #d7f7c2;
                  border-radius: 4px;
                  color: #05690d;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
    .cd--tabs {
      flex: 1;
      &-navbar {
        border-bottom: 1px solid #ebeef1;
        box-sizing: border-box;
        margin-bottom: 40px;
        .tabs-button {
          padding: 0 0 12px 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.32px;
          border: none;
          background: none;
          margin-right: 28px;
          color: #697386;

          &-active {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }
        }
      }
      .overview--tabs {
        .overview--row {
          display: flex;
          flex-direction: column;
          row-gap: 40px;
          .statistics-title__box {
            border-bottom: 1px solid #ebeef1;
            padding-bottom: 4px;
            margin-bottom: 16px;

            & > span {
              line-height: 24px;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #30313d;
            }
          }
          .col-statistics {
            &__wrapper {
              max-width: 700px;
              overflow: scroll;
            }
            display: flex;
            flex-direction: row;
            column-gap: 24px;
            align-items: center;
            .statistics-box {
              white-space: nowrap;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              row-gap: 2px;
              padding-right: 24px;
              //   border-right: 1px solid #ebeef1;
              &:last-child {
                padding-right: 0;
                border-right: none;
              }
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.154px;
                color: #697386;
              }
              b {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.154px;
                color: #30313d;
              }
            }
          }
          .col-kyc {
            display: flex;
            align-items: center;
            column-gap: 16px;
            padding-top: 15px;
            border-top: 1px solid #ebeef1;
            h4 {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #697386;
              margin: 0;
            }
            .col-kyc-verification {
              border-radius: 4px;
              padding: 1px 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;

              &.col-kyc-verifid {
                background: #d7f7c2;
                color: #05690d;
                a {
                  color: #05690d;
                }
              }
              &.col-kyc-no-verifid {
                background: #ffe7f2;
                span {
                  color: #b3063d;
                }
              }
            }
          }
          .col-location {
            h4 {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #30313d;
              margin: 0;
            }
            .card-location-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 12px;
              &:first-child {
                margin-top: 0;
              }
              &-title {
                width: 160px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #697386;
                mix-blend-mode: normal;
              }
              &-value {
                p {
                  margin: 0;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #30313d;
                }
              }
            }
          }
        }
      }
    }
  }
}

.line--border {
  width: 100%;
  height: 1px;
  background: #ebeef1;
  margin: 12px 0 16px;
}
