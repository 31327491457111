.profile-sign-out {
  padding: 15px 24px;
  background: #e75f5f;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
.page-company {
  .company-profile {
    .footer {
      display: none !important;
    }
  }
}

.section-profile {
  .rows {
    &.rows-line {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-direction: column;
      .card {
        padding: 0px;
        box-shadow: none;
        background-color: none;
        &.card-item {
          .line--border {
            margin: 24px 0;
          }
          .card-title {
            margin: 0;
            h6 {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.4px;
              font-feature-settings: "pnum" on, "lnum" on;
              color: #000000;
              margin: 0;
            }
          }
          .card-content {
            .rows {
              margin-top: 12px;
              &:first-child {
                margin-top: 24px;
              }
              &.align-top {
                align-items: flex-start;

                .cols-3 {
                  padding-top: 2px;
                }
              }
              .cols {
                display: flex;
                align-items: center;
                &.cols-3 {
                  width: 160px;
                  margin-right: 8px;
                }
                &.cols-9 {
                  width: fit-content;
                  &.cols-url {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                  }
                  .link-urls {
                    display: block;
                  }
                  .cols-links {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: #8c979f;
                    margin-bottom: 0 !important;
                  }
                }
                * {
                  font-style: normal;
                  font-size: 14px;
                  line-height: 20px;
                }
                span {
                  font-weight: 400;
                  color: #8c979f;
                }
                b {
                  font-weight: 600;
                  color: #30313d;
                }
                a {
                  font-weight: 600;
                  color: #2075f5;
                }
                .edit-url {
                  width: 100%;

                  .rows {
                    display: flex;
                    align-items: flex-start;
                    .cols {
                      width: 100%;

                      &.ml-2 {
                        margin-left: 20px;
                      }
                      textarea {
                        padding: 5px 10px;
                        background: #f4f5f5;
                        border-radius: 5px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #30313d;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        // max-width: 297px;
                        min-width: 400px;
                        width: 100%;
                        // height: 58px;
                        resize: none;
                        min-height: 58px;
                        overflow-y: auto;
                        // word-wrap: break-word;

                        &.active {
                          background: none;
                        }
                      }
                      button {
                        background: none;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .rows {
            display: flex;
            align-items: center;
            flex-direction: rows;

            .card-data {
              h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 36px;
                letter-spacing: 0.340402px;
                color: #30313d;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #a3acba;
                margin: 0;
              }
            }

            &.information {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin: 0 0 24px;
              .cols {
                &:first-child {
                  margin-bottom: 10px;
                }
              }
              b {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #8c979f;
                width: 160px;
                display: inline-block;
              }
              span {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #30313d;
                a {
                  color: #2075f5;
                }
              }
            }
          }
        }
      }
    }
  }
}
