.users-charts-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .top-filter {
    display: flex;
    justify-content: space-between;
  }
  .charts-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    border: 0.5px solid #ebeef1;
  }
}
.donut-charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid #ebeef1;
}
.twice-width {
  grid-column: span 2;
}
