/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import "common";
@import "fonts";
@import "variables";
@import "animations";
@import "main";

// Pages

@import "pages/login";

// Components

@import "components/sidebar";
@import "components/pagination";
@import "components/modal";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "SF-Pro", "Helvetica", sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  // background: #ffffff !important;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.input-error-message {
  height: 18px !important;
  color: $error-color;
  font-size: 12px !important;
  line-height: 14px;
  font-weight: 400;
  margin-top: 10px;
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}

.input-info-message {
  max-height: 18px;
  color: gray;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c979f;
  margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #8c979f;
  background-color: #f3f6f9 !important;
  box-shadow: 0 0 0 30px #f3f6f9 inset !important;
  -webkit-box-shadow: 0 0 0 30px #f3f6f9 inset !important;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  .tag-item {
    margin-right: 9px;
    background-color: #f5f8fa;
    border-radius: 8px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2075f5;
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      &:hover,
      &:active {
        opacity: 0.8;
      }

      svg {
        width: 7.8px;
        height: 7.8px;
      }
    }
  }
}
.promotion-wrapper {
  width: 100%;
  table {
    width: 100%;
    max-width: 50rem;
    font-size: 2rem;
    font-weight: 400;
    thead {
      tr {
        border-bottom: 1px solid #ebeef1;
        th {
          padding: 2rem 0;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            color: gray;
          }
          &:nth-child(2) {
            font-weight: 600;
          }
          padding: 0.8rem 0;
          font-size: 1.2rem;
          text-align: start;
          color: #30313d;
        }
      }
    }
  }
}
