.events {
  &__container {
    width: 100%;
    padding-top: 20px;
    min-height: 700px;

    &--outer {
      .table--pagination {
        margin-top: 20px;
      }
    }
  }

  &__status-wrapper {
    margin-left: auto;
    width: fit-content;
    margin-bottom: 10px;
    background: transparent;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    & > button {
      background: transparent;
      outline: none;
      border: none;
      padding: 4px 8px;

      &.active {
        color: #2075f5;
      }
    }
  }

  &__table {
    width: 100%;

    thead {
      width: 100%;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);

      th {
        padding: 10px;
      }

      th:last-of-type {
        text-align: end;
      }
    }

    tbody {
      tr {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
        padding-left: 10px;
        padding-right: 10px;

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.02);
        }
      }
      td {
        font-size: 16px;
        padding: 10px;
      }

      td:last-of-type {
        text-align: end;
      }
    }
  }
}
